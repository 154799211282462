import { useNavigate } from 'react-router-dom'
import { toast } from './use-toast'
import usePrepareNextRequest from './usePrepareNextRequest'

export const useGoNextMedRequest = (enabled = true) => {
  const navigate = useNavigate()
  const nextMedication = usePrepareNextRequest(enabled)

  const handleNextMedRequest = async () => {
    if (nextMedication) {
      navigate(`/request/${nextMedication.id}`)
    } else {
      toast({
        description: 'We couldnt find a new request for you.'
      })
      navigate('/')
    }
  }

  return handleNextMedRequest
}
