import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { OrderStatusBadge, SetDateIcon } from './sections/previous-orders'
import { cn, formatStringISOToFullDate, getMedRequestSubscriptionText, isMedRequestSubscription } from '@/lib/utils'
import { afterApprovalStatuses, afterRejectionStatuses } from '@/lib/constants/med-requests'
import { format } from 'date-fns'
import type { ReactNode } from 'react'
import SensorRounded from '@/components/ui/icons/sensor-rounded'
import StarIcon from '@/components/ui/icons/star'
import { useMedicationRequestStore } from '@/lib/stores/medication-request'
import MedicationRounded from '@/components/ui/icons/medication-rounded'
import DocumentRounded from '@/components/ui/icons/document-rounded'
import GuardIcon from '@/components/ui/icons/guard'

function HeaderStatusItem({
  label,
  text,
  icon,
  contentClassName,
  className,
  productHasBeenChanged
}: {
  label: string
  text: string
  icon: ReactNode
  contentClassName?: string
  productHasBeenChanged?: boolean
  className?: string
}) {
  return (
    <div className={cn('flex items-start', className)}>
      <div className='flex items-center'>
        {icon}
        <span className='text-[#1D1D1D] text-lg font-bold leading-[170%] mr-4 ml-2 flex-shrink-0'>{label}:</span>
      </div>
      <div
        className={cn('text-[#4E545E] text-lg leading-[170%] min-w-[148px]', productHasBeenChanged, contentClassName)}
      >
        {productHasBeenChanged ? 'Changed to' : text}
        {productHasBeenChanged && <span className='font-medium ml-1'>{text}</span>}
      </div>
    </div>
  )
}

export default function MedicationRequestChip() {
  const { medication } = useMedicationRequestStore()
  const medRequest = useCurrentMedication()
  const medRequestData = medRequest?.data

  const isReorder = medRequestData?.isReorder
  const isSubscription = isMedRequestSubscription(medRequestData?.subscriptionFrequency ?? null)
  const subscriptionStr = getMedRequestSubscriptionText(medRequestData?.subscriptionFrequency ?? null)

  const renderOrderTypeTag = isReorder || isSubscription

  const stageText = isReorder ? 'Reorder' : isSubscription ? `Subscription (${subscriptionStr})` : ''

  const stageStamp = medRequestData?.stage.toLowerCase() ?? ''
  const showApprovedInformation = afterApprovalStatuses.map(e => e.toLowerCase()).includes(stageStamp)
  const showRejectedInformation = afterRejectionStatuses.map(e => e.toLowerCase()).includes(stageStamp)

  const statusLabel = showApprovedInformation ? 'Approved' : showRejectedInformation ? 'Rejected' : null

  const dateToUse = showApprovedInformation
    ? medRequestData?.prescriptionIssueDate
    : showRejectedInformation
      ? medRequestData?.rejectedAt
      : null

  const formattedIdVerifiedTime = medRequestData?.idVerifiedTime
    ? formatStringISOToFullDate(medRequestData.idVerifiedTime)
    : null

  const renderAction = !!medRequestData?.prescriber && statusLabel

  if (!medRequestData) return null

  return (
    <div className='w-full rounded-[36px] px-8 py-4 border border-custom-neutral-200 bg-custom-gray-3 mb-8'>
      <div className='flex justify-between items-center gap-4'>
        <div className='mb-4'>
          <div className='flex items-center gap-2.5 mb-2'>
            <p className='text-custom-neutral-600 text-xl font-medium leading-[34px] tracking-[0.16px]'>
              {medRequestData.orderMedNameString}
            </p>

            {medRequestData.isPriority && <StarIcon className='shrink-0' />}
          </div>
          {medRequestData?.juniorPrescriberRejected && (
            <span className='py-2 px-4 rounded-[24px] h-[36px] bg-[#FFE0D6] text-[#ff4d1a] leading-[20px] text-[13px]'>
              Junior prescriber rejected
            </span>
          )}
        </div>
        <div className='flex align-center gap-2'>
          <OrderStatusBadge
            className='h-[54px] rounded-[56px] px-3 py-[17px] text-sm tracking-[0.1px]'
            stage={medRequestData.stage}
          />
          {renderOrderTypeTag && (
            <OrderStatusBadge
              className='h-[54px] rounded-[56px] px-3 py-[17px] bg-custom-gray-3 border-custom-neutral-800 text-custom-neutral-900 text-sm tracking-[0.1px]'
              stage={stageText}
            />
          )}
        </div>
      </div>
      {!renderAction && formattedIdVerifiedTime && (
        <HeaderStatusItem
          icon={<GuardIcon className='fill-[#4E545E] shrink-0' />}
          text={formattedIdVerifiedTime}
          label='ID verified at'
          contentClassName='max-w-[288px]'
          className='flex-wrap'
        />
      )}

      {renderAction && (
        <>
          <div className='h-[1px] w-full bg-custom-neutral-200 mx-4 my-6' />
          <div className='flex gap-12 min-h-[58px] items-start flex-wrap'>
            <HeaderStatusItem
              icon={<SensorRounded className='shrink-0' />}
              text={medRequestData.prescriber.fullName as string}
              label={`${statusLabel} by`}
              className='flex-wrap'
            />
            {dateToUse && (
              <>
                <div className='min-h-full w-[1px] bg-[#E9EAED]' />
                <HeaderStatusItem
                  icon={<SetDateIcon className='fill-[#4E545E] shrink-0' />}
                  text={format(dateToUse, 'dd MMMM yyyy hh:mm aa')}
                  label={`${statusLabel} at`}
                  contentClassName='max-w-[170px]'
                  className='flex-wrap'
                />
              </>
            )}

            {formattedIdVerifiedTime && (
              <>
                <div className='min-h-full w-[1px] bg-[#E9EAED]' />
                <HeaderStatusItem
                  icon={<GuardIcon className='fill-[#4E545E] shrink-0' />}
                  text={formattedIdVerifiedTime}
                  label='ID verified at'
                  contentClassName='max-w-[170px]'
                  className='flex-wrap'
                />
              </>
            )}

            {medication && (
              <>
                <div className='min-h-full w-[1px] bg-[#E9EAED]' />
                <HeaderStatusItem
                  icon={<MedicationRounded className='fill-[#4E545E] shrink-0' />}
                  text={medication.productName}
                  productHasBeenChanged={medRequestData.orderedItem.hasBeenChanged}
                  label='Product'
                />
              </>
            )}
          </div>

          <div className='h-[1px] w-full bg-custom-neutral-200 mx-4 my-6' />
          {(medRequestData.reasonForApproval || medRequestData.reasonForRejection) && (
            <HeaderStatusItem
              icon={<DocumentRounded className='fill-[#4E545E] shrink-0' />}
              text={
                showApprovedInformation
                  ? (medRequestData.reasonForApproval as string)
                  : (medRequestData.reasonForRejection as string)
              }
              label='Reason'
            />
          )}
        </>
      )}
    </div>
  )
}
