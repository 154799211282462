import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useQuestionnaire } from './useQuestionnaire'
import type { MedRequest } from '@/types/data'
import { useFlag } from '@unleash/proxy-client-react'

export const useAISummary = (medRequest?: MedRequest) => {
  const isFeatureFlagEnabled = useFlag('ai_dental')

  const isEnabled =
    medRequest?.orderedItem?.category === 'Dental Infection' &&
    medRequest?.isPriority === false &&
    !medRequest?.dentalAISummary &&
    isFeatureFlagEnabled

  const { parsedQuestions } = useQuestionnaire(medRequest?.questionaire ?? '')

  const fetchAISummary = async () => {
    if (medRequest?.orderMedNameString && parsedQuestions.length > 0) {
      const consultationMap: Record<string, string[] | string> = {}
      for (const q of parsedQuestions) {
        consultationMap[q.title] = q.answer
      }

      // removes ordernumber
      const str = medRequest.orderMedNameString.split(' ')
      str.shift()
      const medicationName = str.join(' ')

      const data = {
        Consultation: consultationMap,
        MedicationName: medicationName,
        DentalTooSoon: medRequest.tags.includes('DentalTooSoon')
      }

      const response = await axios.post(
        'https://tah.app.n8n.cloud/webhook/d3c67b37-f7d3-4ca8-b0b4-b97a4653e6e2',
        data,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )

      if (response.data?.type === 'error') {
        throw new Error('Failed to get AI summary')
      }

      return response.data.payload
    }
  }

  const { data: aiOutput } = useQuery<string, Error>({
    queryKey: ['aiSummary', medRequest?.id],
    queryFn: () => fetchAISummary(),
    enabled: isEnabled,
    staleTime: Number.POSITIVE_INFINITY
  })

  const data = isFeatureFlagEnabled ? (medRequest?.dentalAISummary ?? aiOutput) : undefined

  return { data }
}
