import http from '@/lib/http'
import { useFilterStore } from '@/lib/stores/filter'
import type { Investigation } from '@/types/data'
import { useQuery } from '@tanstack/react-query'

const useInvestigationFiltering = (enabled = true) => {
  const { filteringOptions } = useFilterStore()

  return useQuery<Array<Investigation>>({
    queryKey: ['investigations'],
    queryFn: () => http.get('/prescriber/investigations'),
    enabled: filteringOptions.investigations && enabled,
    refetchInterval: 30000
  })
}

export default useInvestigationFiltering
