import ImagePlaceholderMini from '@/components/ui/icons/image-placeholder-mini'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { cn } from '@/lib/utils'
import { useGetFlags } from '@/services/flags'
import type { ParsedQuestionnaire } from '@/types/data'
import {
  type ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'

function FlagsCell({
  flags,
  isBatch
}: {
  flags: Array<string>
  isBatch?: boolean
}) {
  const { data: flagsData } = useGetFlags()

  if (!flagsData || !flags?.length || isBatch) return null

  const flagTitles = flags
    .map(flag => flagsData[flag]?.title)
    .filter(Boolean)
    .join(', ')

  return (
    <p className='text-custom-neutral-800 max-w-[32ch] break-words text-sm font-normal leading-[170%]'>{flagTitles}</p>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const columns: ColumnDef<any>[] = [
  {
    accessorKey: 'title',
    header: 'Question',
    cell: ({ row }) => (
      <p className='text-[#080F30] break-words max-w-[32ch] text-base font-medium leading-[170%]'>
        {row.getValue('title')}
      </p>
    )
  },
  {
    accessorKey: 'answer',
    header: 'Answer',
    cell: ({ row }) => {
      if (row.original?.imageUploaded) {
        return (
          <div className='flex items-center gap-2'>
            <ImagePlaceholderMini />
            <span className='text-sm leading-6 font-poppins text-[#4E545E]'>Image Uploaded</span>
          </div>
        )
      }

      return (
        <p className='text-custom-neutral-800 max-w-[32ch] break-words text-sm font-normal leading-[170%]'>
          {row.getValue('answer')}
        </p>
      )
    }
  },
  {
    accessorKey: 'flags',
    header: () => 'Flag',
    cell: ({ row }) => <FlagsCell {...row.original} />
  }
]
type Props = {
  data: ParsedQuestionnaire[]
}

export function ConsultationTable({ data }: Props) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true
  })

  return (
    <div className='w-full'>
      <div className='rounded-md'>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow className='bg-white' key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableHead className='p-6 font-semibold text-base leading-[170%] text-[#080F30]' key={header.id}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className='border-0'>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map(row => {
                return (
                  <TableRow
                    className={cn(
                      'even:bg-white bg-custom-gray-3 first:border rounded-2xl first:border-custom-neutral-200 last:border-r last:border-custom-neutral-200',
                      {
                        '!bg-[#FFE5E5]': !!row.original?.hasFlags
                      }
                    )}
                    key={row.id}
                    data-state={row.getIsSelected() && 'selected'}
                  >
                    {row.getVisibleCells().map(cell => {
                      const link = row.original?.link
                      if (link) {
                        return (
                          <TableCell className='p-6' key={cell.id}>
                            <a href={link} target='_blank' rel='noreferrer'>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </a>
                          </TableCell>
                        )
                      }

                      return (
                        <TableCell className='p-6' key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell colSpan={columns?.length} className='h-24 text-center'>
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
