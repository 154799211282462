import { AdditionalNotesType } from '@/types/data'
import { create } from 'zustand'

type SidebarView = 'navigation-menu' | 'contact' | 'approve'

interface MedicationRequestStore {
  sidebarView: SidebarView
  setSidebarView: (sidebarView: SidebarView) => void

  justification: string
  setJustification: (justification: string) => void

  notes: AdditionalNotesType
  setNotes: (notes: AdditionalNotesType) => void

  contactMessage: string
  setContactMessage: (contactMessage: string) => void
}

export const useInvestigationStore = create<MedicationRequestStore>(set => ({
  sidebarView: 'navigation-menu',
  setSidebarView: (sidebarView: SidebarView) => set({ sidebarView }),

  justification: '',
  setJustification: (justification: string) => set({ justification }),

  notes: {
    pharmacist: '',
    customerService: ''
  },
  setNotes: (notes: AdditionalNotesType) => set({ notes }),

  contactMessage: '',
  setContactMessage: (contactMessage: string) => set({ contactMessage })
}))

export const resetInvestigationStore = () => useInvestigationStore.setState(useInvestigationStore.getInitialState())
