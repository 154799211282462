import http from '@/lib/http'
import { useQuery } from '@tanstack/react-query'
import { queryClient } from './queryClient'

export const useLockItemById = (id: string, enabled: boolean) => {
  useQuery({
    queryKey: [`lock-${id}`],
    refetchInterval: 1000 * 60,
    staleTime: 1000 * 10,
    queryFn: async () => {
      const success = await http.post<boolean, boolean>(`/prescriber/med-requests/${id}/lock`)
      if (!success) {
        queryClient.invalidateQueries({ queryKey: ['medRequestFiltering'] })
        queryClient.invalidateQueries({ queryKey: [id] })
      }
      return true
    },
    enabled
  })
}
