import SingleCombobox from '@/components/common/single-combobox'
import { useMedicationRequestStore } from '@/lib/stores/medication-request'
import { useGetMedications } from '@/services/medications'
import type { Medication } from '@/types/data'
import { addWeeks, format, isAfter, isToday, subWeeks } from 'date-fns'
import { useEffect, useMemo } from 'react'
import { DatePicker } from '../datepicker'
import TextareaWithLabel from '../textarea'

import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { SetDateIcon, TooltipWrapper } from './previous-orders'
import { useToast } from '@/hooks/use-toast'
import usePatientOrderHistory from '@/lib/api/med-requests/order-history'
import { afterApprovalStatuses } from '@/lib/constants/med-requests'

export default function PrescriptionDetails({
  isEditable
}: {
  isEditable: boolean
}) {
  const {
    medicationIssueDate,
    setMedicationIssueDate,
    medicationDirection,
    setMedicationDirection,
    medication,
    setMedication
  } = useMedicationRequestStore()

  const { toast } = useToast()
  const medRequest = useCurrentMedication()
  const medRequestData = medRequest?.data
  const { data: previousOrders } = usePatientOrderHistory(
    medRequestData?.patient.id ?? '',
    !!medRequestData?.patient.id
  )

  const { advancedPreviousOrderDate, lastOrderedDate } = useMemo(() => {
    if (!previousOrders) {
      return { advancedPreviousOrderDate: null, lastOrderedDate: null }
    }

    const filteredPreviousOrders = previousOrders
      .filter(order => order.id !== medRequestData?.id)
      .sort((a, b) => {
        const aDate = new Date(a.closingDate ?? a.prescriptionIssueDate)
        const bDate = new Date(b.closingDate ?? b.prescriptionIssueDate)

        return bDate.getTime() - aDate.getTime()
      })

    const twelveWeeksAgo = subWeeks(new Date(), 12)
    let lastOrderedDate: Date | null = null
    const advancedSetDate = filteredPreviousOrders.find(order => {
      const orderDate = new Date(order.closingDate ?? order.prescriptionIssueDate)
      const findResult =
        afterApprovalStatuses.map(entry => entry.toLowerCase()).includes(order.stage.toLowerCase()) &&
        order.orderedItem.category === medRequestData?.orderedItem.category &&
        orderDate.getTime() > twelveWeeksAgo.getTime()

      if (findResult) {
        lastOrderedDate = orderDate
      }

      return findResult
    })

    if (advancedSetDate) {
      const originalDate = new Date(advancedSetDate.closingDate ?? advancedSetDate.prescriptionIssueDate)
      return {
        advancedPreviousOrderDate: addWeeks(originalDate, 12),
        lastOrderedDate
      }
    }

    return { advancedPreviousOrderDate: null, lastOrderedDate: null }
  }, [previousOrders, medRequestData])

  const isFutureDate = isAfter(medicationIssueDate, new Date())

  const showNoMedicationDirectionBadge = isEditable && !medicationDirection

  const { data: medications, isLoading } = useGetMedications()

  const parsedMedicationOptions = useMemo(() => {
    return medications
      ? medications.map((medication: Medication) => ({
          label: medication.productName,
          value: medication.id
        }))
      : []
  }, [medications])

  const onMedicationChange = (id: string) => {
    const medicationData = medications?.find((medication: Medication) => medication.id === id)

    if (medicationData?.type === 'OTC') {
      setMedicationDirection('')
    } else {
      setMedicationDirection(medRequestData?.medicationDirections ?? medicationData?.medicationDirections ?? '')
    }
    setMedication(medicationData ?? null)
  }

  const findPrescribedMedAndMakeDefaultValue = () => {
    const wooSKU = medRequestData?.wooSKU

    if (wooSKU && medications) {
      const matchingMedication = medications.find((medication: Medication) => medication.wooSKU === wooSKU)
      if (matchingMedication) {
        onMedicationChange(matchingMedication.id)
      } else {
        setMedicationDirection('')
        setMedication(null)
      }
    } else {
      setMedicationDirection('')
      setMedication(null)
    }
  }

  useEffect(() => {
    if (medications) {
      findPrescribedMedAndMakeDefaultValue()
    }
  }, [medications, medRequestData?.id])

  if (!medRequestData) return null

  return (
    <div>
      <p
        id='prescription-details'
        className='text-custom-neutral-600 text-xl font-semibold leading-[133.4%] font-feature-settings-liga-off font-feature-settings-clig-off mb-6'
      >
        Prescription details
      </p>
      <p id='select-medication' className='text-[#1D1D1D]  text-base font-medium leading-[170%] mb-4'>
        Select medication
      </p>
      <SingleCombobox
        isDisabled={isLoading || !isEditable}
        options={parsedMedicationOptions ?? []}
        onValueChange={onMedicationChange}
        title='Medication'
        defaultValue={medication?.id}
      />

      <p id='medication-direction' className=' text-[#1D1D1D] text-base font-medium leading-[170%] my-4'>
        Medication direction
      </p>
      {showNoMedicationDirectionBadge && medication?.type !== 'OTC' && (
        <div className='mb-4 rounded-2xl mt-4 border border-custom-neutral-200 bg-[#FFE5E5] flex px-6 py-4 justify-between items-center'>
          <p className='text-[#080F30] text-base font-normal leading-[170%]'>
            This medication doesn't have any directions. Please
            <b>{''} add directions before approving</b> the medication request.
          </p>
        </div>
      )}

      <TextareaWithLabel
        isDisabled={!isEditable}
        defaultValue={medRequestData.medicationDirections ?? ''}
        value={medicationDirection}
        title='Direction'
        onChange={e => setMedicationDirection(e.target.value)}
        hasError={showNoMedicationDirectionBadge && medication?.type !== 'OTC'}
      />

      <div className='flex flex-col gap-[2.18rem]'>
        <p id='issue-date' className=' text-[#1D1D1D] text-base font-medium leading-[170%] my-4'>
          Issue Date
        </p>
        <div className='rounded-2xl border border-custom-neutral-200 bg-[#EBEFFA] flex px-6 py-4 justify-between items-center'>
          <p className='text-[#080F30] text-base font-normal leading-[170%]'>Medication Issue Date</p>

          <div className='flex flex-col gap-3 w-max'>
            <div className='flex gap-[26px]'>
              <p className='text-[#080F30] text-base font-semibold leading-[170%]'>
                {format(medicationIssueDate, 'd MMMM yyyy')} {isToday(medicationIssueDate) ? '(Today)' : ''}
              </p>

              <DatePicker
                isDisabled={!isEditable}
                fromDate={new Date()}
                date={medicationIssueDate}
                setDate={date => {
                  if (date) {
                    date.setHours(8, 0, 0, 0)
                  }
                  setMedicationIssueDate(date || new Date())
                }}
              />
            </div>
          </div>
        </div>

        {lastOrderedDate && (
          <div className='flex items-center gap-4'>
            <span className='font-poppins text-base leading-7 font-bold text-[#1D1D1D]'>Last ordered date:</span>
            <span className='font-poppins text-base leading-7 text-[#4E545E]'>
              {format(lastOrderedDate, 'd MMMM yyyy')}
            </span>
            <TooltipWrapper
              trigger={
                <button
                  disabled={!isEditable}
                  type='button'
                  className='flex items-center gap-1 cursor-pointer disabled:cursor-not-allowed disabled:opacity-50'
                  onClick={() => {
                    setMedicationIssueDate(advancedPreviousOrderDate)
                    toast({
                      title: 'Issue date set',
                      description: `Issue date set to [${format(advancedPreviousOrderDate, 'dd MMMM yyyy')}]`
                    })
                  }}
                >
                  <SetDateIcon />
                </button>
              }
              content={
                <p className='text-white text-xs font-medium leading-4'>Set issue date to 12 weeks from this date</p>
              }
            />
          </div>
        )}
      </div>

      {isFutureDate && (
        <div className='rounded-2xl mt-4 border border-custom-neutral-200 bg-[#FFE5E5] flex px-6 py-4 justify-between items-center'>
          <p className='text-[#080F30] text-base font-normal leading-[170%]'>
            This request is scheduled for approval on <b>{format(medicationIssueDate, 'MMMM d, yyyy')}.</b>
          </p>
        </div>
      )}
    </div>
  )
}
