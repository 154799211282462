import http from '@/lib/http'
import type { Investigation } from '@/types/data'
import { useQuery } from '@tanstack/react-query'

export default function usePreviousInvestigations({
  patientId,
  enabled
}: {
  patientId: string
  enabled?: boolean
}) {
  return useQuery<Investigation[]>({
    queryKey: ['previousInvestigationsByPatientId', patientId],
    queryFn: () =>
      http.get(`/prescriber/investigations`, {
        params: { patientId }
      }),
    enabled
  })
}
