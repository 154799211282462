import { useCurrentInvestigation } from '@/hooks/useCurrentInvestigation'
import { InvestigationStatusBadge } from '../medication-request/sections/previous-investigations'
import Lock2 from '@/components/ui/icons/lock2'

export default function Header() {
  const { data: investigation } = useCurrentInvestigation()

  if (!investigation) return null

  return (
    <>
      {investigation.locked && (
        <div className='flex items-center rounded-2xl border border-[#FF8080] bg-[#FFF2F2] gap-4 px-6 py-4'>
          <Lock2 />
          <p className='text-[#080F30] font-poppins text-base font-normal leading-[170%]'>
            This Investigation is currently <span className='font-semibold'>LOCKED</span>. You can't make any changes to
            it at the moment.
          </p>
        </div>
      )}
      <div className='w-full rounded-[36px] px-8 py-4 border border-custom-neutral-200 bg-custom-gray-3 mb-8 flex justify-between items-center'>
        <p className='font-poppins font-medium w-max text-xl leading-[34px] text-[#626A77]'>{investigation.name}</p>
        <InvestigationStatusBadge stage={investigation.stage} className='px-3 py-[17px] text-sm rounded-3xl' />
      </div>
    </>
  )
}
