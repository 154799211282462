import axios from 'axios'

export const msgHTTP = axios.create({
  baseURL: import.meta.env.VITE_MESSAGING_API_URL,
  timeout: 10000,
  timeoutErrorMessage: 'Request timed out',
  adapter: 'fetch'
})

msgHTTP.interceptors.response.use(
  res => res.data?.data ?? res.data,
  error => {
    console.error('Error in messaging http', error?.response?.request?.url, error?.response.data)
    return Promise.reject(error?.response?.data)
  }
)
