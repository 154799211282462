import { useQuery } from '@tanstack/react-query'
import { msgHTTP } from '@/lib/msgHTTP'

type GetMessagingTemplateArgs = {
  vendorHash?: string
  data?: Record<string, unknown>
  enabled: boolean
  templateName: string
}

export const useGetMessagingTemplate = ({ vendorHash, data, enabled, templateName }: GetMessagingTemplateArgs) => {
  return useQuery({
    enabled,
    queryKey: ['messagingTemplate', templateName],
    staleTime: 0,
    queryFn: () =>
      msgHTTP.post<string, string>(`/email/${templateName}/render`, {
        vendorHash,
        data,
        templateName
      })
  })
}
