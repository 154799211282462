import ListAltRounded from '@/components/ui/icons/listaltrounded'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { getMedRequestSubscriptionText, getPreviousOrderDateToUse, isMedRequestSubscription } from '@/lib/utils'
import usePatientOrderHistory from '@/lib/api/med-requests/order-history'
import { cn, getOrderStatusBadgeClasses } from '@/lib/utils'
import type { MedRequest } from '@/types/data'
import { TooltipArrow } from '@radix-ui/react-tooltip'
import { format } from 'date-fns'
import { useMemo } from 'react'

import { Link } from 'react-router-dom'
import { useGetMedications } from '@/services/medications'
import { useCurrentInvestigation } from '@/hooks/useCurrentInvestigation'

interface OrderStatusBadgeProps {
  stage: string
  className?: string
}

export function OrderStatusBadge({ stage = 'Further answers needed', className }: OrderStatusBadgeProps) {
  return (
    <span
      className={cn(
        'h-9 max-w-[30ch] truncate p-2 px-4 rounded-2xl border text-[13px] leading-[20px]',
        getOrderStatusBadgeClasses(stage),
        className
      )}
    >
      {stage}
    </span>
  )
}

export function SetDateIcon({ ...props }) {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M17 2C16.45 2 16 2.45 16 3V4H8V3C8 2.45 7.55 2 7 2C6.45 2 6 2.45 6 3V4H5C3.89 4 3.01 4.9 3.01 6L3 20C3 21.1 3.89 22 5 22H19C20.1 22 21 21.1 21 20V6C21 4.9 20.1 4 19 4H18V3C18 2.45 17.55 2 17 2ZM19 20H5V10H19V20ZM11 13C11 12.45 11.45 12 12 12C12.55 12 13 12.45 13 13C13 13.55 12.55 14 12 14C11.45 14 11 13.55 11 13ZM7 13C7 12.45 7.45 12 8 12C8.55 12 9 12.45 9 13C9 13.55 8.55 14 8 14C7.45 14 7 13.55 7 13ZM15 13C15 12.45 15.45 12 16 12C16.55 12 17 12.45 17 13C17 13.55 16.55 14 16 14C15.45 14 15 13.55 15 13ZM11 17C11 16.45 11.45 16 12 16C12.55 16 13 16.45 13 17C13 17.55 12.55 18 12 18C11.45 18 11 17.55 11 17ZM7 17C7 16.45 7.45 16 8 16C8.55 16 9 16.45 9 17C9 17.55 8.55 18 8 18C7.45 18 7 17.55 7 17ZM15 17C15 16.45 15.45 16 16 16C16.55 16 17 16.45 17 17C17 17.55 16.55 18 16 18C15.45 18 15 17.55 15 17Z'
        className={cn('fill-[#153CB7]', props.className)}
      />
    </svg>
  )
}

export function TooltipWrapper({
  trigger,
  content
}: {
  trigger: React.ReactNode
  content: React.ReactNode
}) {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>{trigger}</TooltipTrigger>
        <TooltipContent className='rounded-[4px] p-2 w-[260px] bg-[rgba(97,97,97,0.90)]'>{content}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export function Order({ order }: { order: MedRequest }) {
  const { data: medications } = useGetMedications()
  const medName =
    (medications ?? []).find(med => med.id === order.orderedItem.id)?.productName ?? 'Unknown Medication Item'
  const isReorder = order.isReorder

  const isSubscription = isMedRequestSubscription(order.subscriptionFrequency)
  const renderOrderTypeTag = isReorder || isSubscription

  const subscriptionStr = getMedRequestSubscriptionText(order.subscriptionFrequency)

  const orderDate = getPreviousOrderDateToUse(order)

  const selectedMedication = medications?.find(med => med.id === order.orderedItem.id)

  const renderChangedDirections =
    typeof selectedMedication?.medicationDirections === 'string' &&
    typeof order.medicationDirections === 'string' &&
    selectedMedication?.medicationDirections !== order.medicationDirections

  const isTooltipAvailable = Boolean(
    renderChangedDirections ||
      order.reasonForHold ||
      order.patientsResponseForHold ||
      order.reasonForRejection ||
      order.additionalNotes?.pharmacist?.length ||
      order.additionalNotes?.customerService?.length
  )

  return (
    <Link
      target='_blank'
      rel='noopener noreferrer'
      to={`/request/${order.id}`}
      className='flex h-[180px] flex-col justify-center px-8 py-4 gap-4 rounded-3xl border border-custom-neutral-200 bg-[#FAFAFB] cursor-pointer hover:border-[#153CB7] transition-colors'
    >
      <div className='flex w-full justify-between items-center'>
        <div>
          <p className='text-[#1D1D1D] text-sm font-bold leading-[170%]'>{medName}</p>
          {order.orderedItem.category && (
            <p className='text-custom-neutral-800 text-sm leading-[170%] overflow-hidden truncate'>
              {order.orderedItem.category}
            </p>
          )}
        </div>
        <OrderStatusBadge stage={order.stage} />
      </div>
      <hr className='border-custom-neutral-200 border-[1px]' />
      <div className='flex w-full justify-between items-center'>
        <div>
          <div className='flex items-center gap-2.5 mb-4'>
            <p className='text-custom-neutral-800 text-sm leading-[170%] w-max'>{format(orderDate, 'dd MMMM yyyy')}</p>
          </div>
          {renderOrderTypeTag && (
            <span className='h-9 max-w-[30ch] text-[#24272B] truncate p-2 px-4 rounded-2xl border text-[13px] leading-[20px] border-[#4E545E]'>
              {isReorder ? 'Re-order' : `Subscription ${subscriptionStr}`}
            </span>
          )}
        </div>
        {isTooltipAvailable && (
          <TooltipWrapper
            trigger={
              <div className='flex w-12 h-12 p-2 justify-center items-center rounded-2xl border border-[#A0A3A6]'>
                <ListAltRounded />
              </div>
            }
            content={
              <>
                {renderChangedDirections && (
                  <>
                    <h3 className='text-sm font-bold text-white'>Changed directions</h3>
                    <p className='text-white text-xs font-medium leading-[14px]'>{order.medicationDirections}</p>
                  </>
                )}
                {order.reasonForHold && (
                  <>
                    <h3 className='text-sm font-bold text-white'>On hold reason</h3>
                    <p className='text-white text-xs font-medium leading-[14px]'>{order.reasonForHold}</p>
                  </>
                )}
                {order.patientsResponseForHold && (
                  <>
                    <h3 className='text-sm font-bold text-white'>Patient's response</h3>
                    <p className='text-white text-xs font-medium leading-[14px]'>{order.patientsResponseForHold}</p>
                  </>
                )}
                {order.reasonForRejection && (
                  <>
                    <h3 className='text-sm font-bold text-white'>Rejection Reason</h3>
                    <p className='text-white text-xs font-medium leading-[14px]'>{order.reasonForRejection}</p>
                  </>
                )}
                {order.additionalNotes?.pharmacist && (
                  <>
                    <h3 className='text-sm font-bold text-white'>Pharmacy notes</h3>
                    <p className='text-white text-xs font-medium leading-[14px]'>{order.additionalNotes.pharmacist}</p>
                  </>
                )}
                {order.additionalNotes?.customerService && (
                  <>
                    <h3 className='text-sm font-bold text-white'>Customer Service notes</h3>
                    <p className='text-white text-xs font-medium leading-[14px]'>
                      {order.additionalNotes.customerService}
                    </p>
                  </>
                )}
                <TooltipArrow className='fill-[rgba(97,97,97,0.90)]' />
              </>
            }
          />
        )}
      </div>
    </Link>
  )
}

export default function PreviousOrders({
  patientOrigin = 'medication-request',
  patientId
}: {
  patientId: string
  patientOrigin?: 'investigation' | 'medication-request'
}) {
  const { data: previousOrders } = usePatientOrderHistory(patientId)
  const investigation = useCurrentInvestigation(patientOrigin === 'investigation')
  const medRequest = useCurrentMedication(
    !!(patientOrigin === 'medication-request' || !!investigation?.data?.medicationRequestId),
    investigation?.data?.medicationRequestId,
    false
  )

  const ordersToRender = useMemo(() => {
    if (!previousOrders) {
      return []
    }

    const filteredPreviousOrders = previousOrders
      .filter(order => order.id !== medRequest?.data?.id)
      .sort((a, b) => {
        const aDate = new Date(a.closingDate ?? a.prescriptionIssueDate)
        const bDate = new Date(b.closingDate ?? b.prescriptionIssueDate)

        return bDate.getTime() - aDate.getTime()
      })

    return filteredPreviousOrders
  }, [previousOrders, medRequest])

  if (!ordersToRender.length) return null

  return (
    <div id='previous-orders'>
      <div className='mb-6 flex align-items justify-between'>
        <p className='text-custom-neutral-600 text-xl font-semibold leading-[133.4%] '>Previous Orders</p>
      </div>
      <div className='grid grid-cols-2 gap-4 max-h-[410px] overflow-y-auto'>
        {ordersToRender.map((order, index) => (
          <Order key={order.id || index} order={order} />
        ))}
      </div>
    </div>
  )
}
