import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { isMedRequestInEditableStage } from '@/lib/utils'
import MedicationRequestChip from '../med-request-chip'
import Lock2 from '@/components/ui/icons/lock2'

const Header = () => {
  const medRequest = useCurrentMedication()
  const data = medRequest?.data

  const isInEditableStage = isMedRequestInEditableStage(data?.stage ?? '')

  if (!data) return null

  return (
    <>
      {data.locked && (
        <div className='flex items-center rounded-2xl border border-[#FF8080] bg-[#FFF2F2] gap-4 px-6 py-4'>
          <Lock2 />
          <p className='text-[#080F30] font-poppins text-base font-normal leading-[170%]'>
            This medication request is currently <span className='font-semibold'>LOCKED</span>. You can't make any
            changes to it at the moment.
          </p>
        </div>
      )}
      {!isInEditableStage && (
        <div className='mb-20 rounded-2xl border border-[#E9EAED] bg-[#FFFAEB] py-4 px-6'>
          <p className='text-[#080F30] leading-[170%]'>
            You are viewing a <b>PREVIOUS</b> medication request. This page is <b>read-only</b> and cannot be edited.
          </p>
        </div>
      )}
      <MedicationRequestChip />
    </>
  )
}

export default Header
