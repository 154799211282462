import { useCurrentInvestigation } from '@/hooks/useCurrentInvestigation'
import { useGoNextInvestigation } from '@/hooks/useGoNextInvestigation'
import { useInvestigationStore } from '@/lib/stores/investigation'
import { useEffect, useState } from 'react'

const { setSidebarView } = useInvestigationStore.getState()

export default function InvestigationFooter() {
  const investigation = useCurrentInvestigation()
  const goNextInvestigationRequest = useGoNextInvestigation(!!investigation?.data)
  const [isScrolledDown, setIsScrolledDown] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.innerHeight + window.scrollY
      const documentHeight = document.documentElement.scrollHeight
      const isAtBottom = scrollPosition >= documentHeight * 0.92 // 80% of viewport
      if (!isScrolledDown && isAtBottom) setIsScrolledDown(true)
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll() // Check initial scroll position

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const isApprovalDisabled = !investigation || !isScrolledDown
  const data = investigation?.data
  const hideFooter = data?.locked || data?.stage !== 'Results ready'

  if (!data || hideFooter) return null

  return (
    <div className='fixed left-0 bottom-0 flex w-full h-[88px] flex-col justify-center items-center flex-shrink-0 border-t border-[#FAFAFB] bg-white shadow-[0px_-8px_80px_0px_rgba(0,0,0,0.10)]'>
      <div className='flex justify-center gap-4 w-full max-w-[994px]'>
        {data.locked ? (
          <button
            type='button'
            onClick={() => {
              goNextInvestigationRequest()
            }}
            className='flex w-[200px] h-14 flex-col justify-center items-center gap-2 rounded-2xl bg-[#153CB7] py-4 px-6 hover:bg-[#0F2E8A] transition-colors duration-200'
          >
            <span className='text-white text-center text-sm font-semibold leading-[170%]'>Skip to Next</span>
          </button>
        ) : (
          <>
            <button
              type='button'
              onClick={() => {
                setSidebarView('approve')
              }}
              className={`flex w-[200px] h-14 flex-col justify-center items-center gap-2 rounded-2xl bg-[#153CB7] py-4 px-6 hover:bg-[#0F2E8A] transition-colors duration-200 ${
                isApprovalDisabled ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={isApprovalDisabled}
            >
              <span className='text-white text-center text-sm font-semibold leading-[170%]'>Share Results</span>
            </button>

            <button
              type='button'
              onClick={() => {
                setSidebarView('contact')
              }}
              className='flex w-[200px] h-14 flex-col justify-center items-center rounded-2xl bg-white py-4 px-6 border border-custom-neutral-200 hover:bg-custom-neutral-200 transition-colors duration-200'
            >
              <span className='text-[#080F30] text-center text-sm font-semibold leading-[170%]'>Notify to Retest</span>
            </button>
          </>
        )}
      </div>
    </div>
  )
}
