import { useMedicationRequestStore } from '@/lib/stores/medication-request'
import TextareaWithLabel from '../textarea'
import useMedRequestAction from '@/lib/api/med-requests/action'
import { cn, medReqFieldValidationNotifications } from '@/lib/utils'
import LoadingSpinner from '@/components/ui/icons/loading-spinner'
import SingleCombobox from '@/components/common/single-combobox'
import { useState } from 'react'
import useApproveMedicationRequest from '@/lib/api/med-requests/approve'
import { useQuestionnaire } from '@/hooks/useQuestionnaire'
import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { useInvestigationStore } from '@/lib/stores/investigation'
import { useCurrentInvestigation } from '@/hooks/useCurrentInvestigation'
import useApproveInvestigation from '@/lib/api/investigations/approve'
import useInvestigationAction from '@/lib/api/investigations/action'
import { useGetMessagingTemplate } from '@/hooks/useGetMessagingTemplate'
import InvestigationTemplatePreview from '../../investigation/template-preview'

const ApprovalReasonSection = ({
  patientOrigin = 'medication-request'
}: {
  patientOrigin?: 'medication-request' | 'investigation'
}) => {
  const {
    justification: medReqJustification,
    setJustification: setMedReqJustification,
    setSidebarView: setMedReqSidebarView
  } = useMedicationRequestStore()
  const {
    justification: investigationJustification,
    setJustification: setInvestigationJustification,
    setSidebarView: setInvestigationSidebarView
  } = useInvestigationStore()

  const isMedReq = patientOrigin === 'medication-request'
  const justification = isMedReq ? medReqJustification : investigationJustification
  const setSidebarView = isMedReq ? setMedReqSidebarView : setInvestigationSidebarView
  const setJustification = isMedReq ? setMedReqJustification : setInvestigationJustification
  const fieldValidationNotifications = isMedReq ? medReqFieldValidationNotifications : () => true

  const medReqAction = useMedRequestAction({
    enabled: !!(patientOrigin === 'medication-request')
  })
  const investigationAction = useInvestigationAction()
  const action = isMedReq ? medReqAction : investigationAction

  const [template, setTemplate] = useState('')

  const investigation = useCurrentInvestigation(patientOrigin === 'investigation')
  const medRequest = useCurrentMedication(
    !!(patientOrigin === 'medication-request' || investigation?.data?.medicationRequestId),
    investigation?.data?.medicationRequestId,
    isMedReq
  )

  const { templateOptions } = useQuestionnaire(medRequest?.data?.questionaire ?? '')

  const {
    data: shareResultsTemplate,
    isLoading,
    error
  } = useGetMessagingTemplate({
    vendorHash: investigation.data?.vendorHash ?? '',
    templateName: 'conversation-share-results',
    data: {
      patient: {
        firstName: investigation.data?.patient.firstName ?? '',
        lastName: investigation.data?.patient.lastName ?? ''
      },
      testName: investigation.data?.results?.tests[0]?.testName ?? '',
      results: {
        tests:
          investigation.data?.results?.tests?.map(test => ({
            testName: test.testName,
            resultLines: test.resultLines?.map(line => ({
              testName: line.testName,
              result: line.result
            }))
          })) ?? []
      }
    },
    enabled: Boolean(!isMedReq && investigation?.data)
  })

  const approveMedicationRequest = useApproveMedicationRequest({
    patientOrigin
  })
  const approveInvestigation = useApproveInvestigation({
    patientOrigin,
    message: shareResultsTemplate ?? ''
  })
  const approveAction = isMedReq ? approveMedicationRequest : approveInvestigation

  const isDisabled = (isMedReq && !justification) || action.isPending || (!isMedReq && !shareResultsTemplate)

  if (!approveAction || !medRequest) return null

  return (
    <div className='sticky top-[88px] h-fit flex min-w-[25vw] flex-col rounded-2xl border border-custom-neutral-200 shadow-[0px_0px_1px_0px_rgba(113,128,150,0.04),_0px_4px_8px_0px_rgba(113,128,150,0.08)]'>
      <div className='p-6'>
        <p className='mb-2 text-custom-neutral-600 text-xl font-semibold leading-[133.4%]'>Approval Reason</p>
        {isMedReq && (
          <p className='text-custom-neutral-500 text-sm leading-[170%]'>
            {`Provide the reasons for approving the ${isMedReq ? 'medication request' : 'investigation'}.`}
          </p>
        )}
      </div>
      <div className='p-6'>
        {isMedReq && (
          <>
            <p className='mb-4 text-[#1D1D1D] font-medium leading-[170%]'>Select a template</p>
            <SingleCombobox
              title='Templates'
              isDisabled={templateOptions?.approvalOptions.length === 0}
              defaultValue={template}
              onValueChange={value => {
                setJustification(value)
                setTemplate(value)
              }}
              options={templateOptions?.approvalOptions ?? []}
            />
            <div className='mt-4 mb-12'>
              <TextareaWithLabel value={justification} onChange={e => setJustification(e.target.value)} autoFocus />
            </div>
          </>
        )}
        {!isMedReq && (
          <InvestigationTemplatePreview template={shareResultsTemplate} isLoading={isLoading} error={Boolean(error)} />
        )}
        <button
          type='button'
          onClick={() => {
            if (!fieldValidationNotifications('approve')) return
            approveAction()
            setJustification('')
            setSidebarView('navigation-menu')
          }}
          disabled={isDisabled}
          className={cn(
            'flex justify-center items-center gap-2 mb-4 h-14 rounded-2xl bg-[#153CB7] text-white text-sm font-semibold leading-[170%] w-full transition-all duration-200 ease-in-out',
            isDisabled && 'opacity-50 cursor-not-allowed',
            action.isPending && 'cursor-wait opacity-70'
          )}
        >
          {action.isPending ? (
            <>
              <LoadingSpinner className='w-6 h-6 text-white animate-spin' />
              <span>Loading..</span>
            </>
          ) : (
            'Submit'
          )}
        </button>
        <button
          type='button'
          onClick={() => {
            setJustification('')
            setSidebarView('navigation-menu')
          }}
          className='flex h-14 w-full color-[#1D1D1D] items-center justify-center gap-4 rounded-2xl border border-custom-neutral-200 bg-white px-6 text-custom-neutral-600 text-sm font-semibold  hover:bg-custom-neutral-200 transition-colors duration-200
        leading-[170%]'
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

export default ApprovalReasonSection
