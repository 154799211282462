import ListAltRounded from '@/components/ui/icons/listaltrounded'
import { getInvestigationStatusBadgeClasses } from '@/lib/utils'
import { cn } from '@/lib/utils'
import type { Investigation } from '@/types/data'
import { TooltipArrow } from '@radix-ui/react-tooltip'
import { format } from 'date-fns'
import { Link } from 'react-router-dom'
import { TooltipWrapper } from './previous-orders'
import usePreviousInvestigations from '@/hooks/usePreviousInvestigations'
import { useMemo } from 'react'
import { useUser } from '@clerk/clerk-react'

export function InvestigationStatusBadge({
  stage = 'Started',
  className
}: {
  stage: string
  className?: string
}) {
  return (
    <span
      className={cn(
        'p-2 px-4 rounded-2xl border text-[13px] leading-5 grid place-items-center',
        getInvestigationStatusBadgeClasses(stage),
        className
      )}
    >
      {stage}
    </span>
  )
}

export function InvestigationTile({
  investigation
}: {
  investigation: Investigation
}) {
  const testResults = investigation.results?.tests || []

  const renderResultLine = (line: (typeof testResults)[0]['resultLines'][0]) => {
    const hasUOM = line.UOM && line.UOM.trim() !== ''
    const hasRange = line.rangeText && line.rangeText.trim() !== ''
    const hasFlag = line.flag && line.flag.trim() !== ''

    return (
      <div className='ml-2 inline-flex gap-1 flex-wrap items-baseline'>
        <p className='text-white text-sm leading-[170%]'>{line.testName}</p>
        <span>{': '}</span>
        <div className='text-sm leading-[170%] whitespace-pre text-gray-300'>
          {line.result}
          {hasUOM && ` ${line.UOM}`}
          {hasRange && ` (${line.rangeText})`}
          {hasFlag && ` [${line.flag}]`}
        </div>
      </div>
    )
  }

  const renderTestResults = (test: (typeof testResults)[0]) => {
    return (
      <div className='flex flex-col gap-1'>
        <p className='text-white text-sm font-semibold leading-[170%]'>
          {test.testName}
          {test.testCode && ` (${test.testCode})`}
        </p>
        <div className='flex flex-col gap-0.5'>
          {test.resultLines.map((line, key) => (
            <span key={key}>{renderResultLine(line)}</span>
          ))}
        </div>
      </div>
    )
  }

  return (
    <Link
      target='_blank'
      rel='noopener noreferrer'
      to={`/investigation/${investigation.id}`}
      className='flex h-[180px] flex-col justify-center px-8 py-4 gap-4 rounded-3xl border border-custom-neutral-200 bg-[#FAFAFB] cursor-pointer hover:border-[#153CB7] transition-colors'
    >
      <div className='flex w-full justify-between items-center'>
        <div>
          <p className='text-[#1D1D1D] text-sm font-bold leading-[170%]'>{investigation.name}</p>
          {investigation.category && (
            <p className='text-custom-neutral-800 text-sm leading-[170%] overflow-hidden truncate'>
              {investigation.category}
            </p>
          )}
        </div>
        <InvestigationStatusBadge stage={investigation.stage} />
      </div>
      <hr className='border-custom-neutral-200 border-[1px]' />
      <div className='flex w-full justify-between items-center'>
        <div className='flex flex-col gap-1'>
          <p className='text-custom-neutral-800 text-sm leading-[170%] w-max'>
            {format(new Date(investigation.createdAt), 'dd MMMM yyyy')}
          </p>
        </div>
        {testResults.length > 0 && (
          <TooltipWrapper
            trigger={
              <div className='flex w-12 h-12 p-2 justify-center items-center rounded-2xl border border-[#A0A3A6] relative'>
                <ListAltRounded />
              </div>
            }
            content={
              <>
                <div className='flex flex-col gap-3 max-h-[400px] overflow-y-auto'>
                  {testResults.map((result, idx) => (
                    <div key={idx} className='flex flex-col gap-1'>
                      {renderTestResults(result)}
                    </div>
                  ))}
                </div>
                <TooltipArrow className='fill-[rgba(97,97,97,0.90)]' />
              </>
            }
          />
        )}
      </div>
    </Link>
  )
}

export default function PreviousInvestigations({
  patientId,
  withoutInvestigationId
}: {
  patientId: string
  withoutInvestigationId?: string
}) {
  const { user } = useUser()
  const isJuniorPrescriber = user?.publicMetadata.roles.includes('JUNIOR_PRESCRIBER')

  const { data = [] } = usePreviousInvestigations({
    patientId,
    enabled: !isJuniorPrescriber
  })

  const previousInvestigations = useMemo(
    () => data.filter(entry => entry.id !== withoutInvestigationId),
    [data, withoutInvestigationId]
  )
  if (!previousInvestigations.length) return null

  return (
    <div id='previous-investigations'>
      <div className='mb-6 flex align-items justify-between'>
        <p className='text-custom-neutral-600 text-xl font-semibold leading-[133.4%] '>Previous Investigations</p>
      </div>
      <div className='grid grid-cols-2 gap-4 max-h-[410px] overflow-y-auto'>
        {previousInvestigations.map(investigation => (
          <InvestigationTile key={investigation.id} investigation={investigation} />
        ))}
      </div>
    </div>
  )
}
