export default function GuardIcon({ ...props }) {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M11.19 1.86L4.19 4.97C3.47 5.29 3 6.01 3 6.8V11.5C3 17.05 6.84 22.24 12 23.5C17.16 22.24 21 17.05 21 11.5V6.8C21 6.01 20.53 5.29 19.81 4.97L12.81 1.86C12.3 1.63 11.7 1.63 11.19 1.86ZM9.29 16.79L6.7 14.2C6.31 13.81 6.31 13.18 6.7 12.79C7.09 12.4 7.72 12.4 8.11 12.79L10 14.67L15.88 8.79C16.27 8.4 16.9 8.4 17.29 8.79C17.68 9.18 17.68 9.81 17.29 10.2L10.7 16.79C10.32 17.18 9.68 17.18 9.29 16.79Z'
        fill='#4E545E'
      />
    </svg>
  )
}
