import http from '@/lib/http'
import type { Investigation, MedRequest } from '@/types/data'
import { useQuery, useSuspenseQuery } from '@tanstack/react-query'

export type CutoffTimes = Record<
  'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday',
  {
    is_delivering: boolean
    is_collecting: boolean
    cutoff_time: string
  }
>

export const useTotalMedicationRequests = () => {
  return useSuspenseQuery<{
    beforeCutOff: number
    total: number
    totalInvestigations: number
    cutoffTimes: CutoffTimes
  }>({
    queryKey: ['totalMedicationRequests'],
    queryFn: () =>
      http.get('/prescriber/med-requests/total', {
        timeout: (http.defaults.timeout ?? 10000) * 6
      }),
    refetchInterval: 30000
  })
}

export const useSuspenseMedicationRequestById = (id: string) => {
  return useSuspenseQuery<MedRequest>({
    queryKey: ['medicationRequestById', id],
    queryFn: () => http.get(`/prescriber/med-requests/${id}`),
    staleTime: 0,
    refetchOnMount: true
  })
}

export const useMedicationRequestById = (id: string, enabled: boolean = true) => {
  return useQuery<MedRequest>({
    queryKey: ['medicationRequestById', id],
    queryFn: () => http.get(`/prescriber/med-requests/${id}`),
    enabled
  })
}

export const useInvestigationRequestById = (id: string, enabled: boolean = true) => {
  return useQuery<Investigation>({
    queryKey: ['investigationRequestById', id],
    queryFn: () =>
      http.get(`/prescriber/investigations`, {
        params: {
          investigationId: id
        }
      }),
    enabled
  })
}
