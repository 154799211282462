import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { Investigation } from '@/types/data'
import http from '@/lib/http'
import { useLockItemById } from '@/services/useLockItem'
import usePrepareNextRequest from './usePrepareNextRequest'

export const useCurrentInvestigation = (enabled = true) => {
  const { id } = useParams<{ id: string }>()

  if (!id) {
    throw new Error('No ID provided')
  }

  const investigation = useQuery<Investigation>({
    queryKey: ['investigationRequestById', id],
    queryFn: () =>
      http.get('/prescriber/investigations', {
        params: {
          investigationId: id
        }
      }),
    staleTime: 0,
    refetchOnMount: true,
    enabled
  })

  const data = investigation?.data
  useLockItemById(data?.id ?? '', !!(data && !data?.locked && enabled))
  usePrepareNextRequest(enabled, true)

  return investigation
}
