import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { ConsultationTable } from '../consultation-table'
import { useQuestionnaire } from '@/hooks/useQuestionnaire'

export default function Consultation() {
  const medRequest = useCurrentMedication()
  const { parsedQuestions } = useQuestionnaire(medRequest?.data?.questionaire ?? '')

  if (!parsedQuestions) return null

  return (
    <div id='consultation'>
      <p className='text-custom-neutral-600 text-xl font-semibold leading-[133.4%] mb-6'>Consultation</p>
      <ConsultationTable data={parsedQuestions} />
    </div>
  )
}
