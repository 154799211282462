import { resetMedicationRequestStore, useMedicationRequestStore } from '@/lib/stores/medication-request'
import { ChevronDownIcon } from '@radix-ui/react-icons'
import { useMemo, useState } from 'react'
import { Order } from './sections/previous-orders'

import { Checkbox } from '@/components/ui/checkbox'
import { CancelCircle } from '@/components/ui/icons/cancel-circle'
import { CheckmarkBadge } from '@/components/ui/icons/checkmark-badge'
import { Loading } from '@/components/ui/icons/loading'
import LoadingSpinner from '@/components/ui/icons/loading-spinner'
import { useToast } from '@/hooks/use-toast'
import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { medReqFieldValidationNotifications, getPatientAgeText } from '@/lib/utils'
import useMedRequestAction, { invalidateRelevantActionQueries } from '@/lib/api/med-requests/action'
import type { BatchMedRequest } from '@/types/data'
import { Column } from './sections/patient-data'
import { useGoNextMedRequest } from '@/hooks/useGoNextMedRequest'
import { useLockItemById } from '@/services/useLockItem'
import { ConsultationTable } from './consultation-table'
import { useQuestionnaire } from '@/hooks/useQuestionnaire'

function BatchOrder({ order }: { order: BatchMedRequest }) {
  const [isExpanded, setIsExpanded] = useState(false)
  const { parsedQuestions: variableQuestions } = useQuestionnaire(order?.questionaire ?? '')

  useLockItemById(order.id, !order.locked)

  const { setSelectedBatchOrderIds, selectedBatchOrderIds } = useMedicationRequestStore()

  const handleSelectOrder = (requestId: string) => {
    const isSelected = selectedBatchOrderIds.includes(requestId)
    if (isSelected) {
      setSelectedBatchOrderIds(selectedBatchOrderIds.filter((id: string) => id !== requestId))
    } else {
      setSelectedBatchOrderIds([...selectedBatchOrderIds, requestId])
    }
  }

  const patient = order.patient

  const patientAgeText = useMemo(() => getPatientAgeText(patient?.dob), [patient?.dob])

  const patientName = patient?.firstName ? `${patient?.firstName} ${patient?.lastName}` : '-'

  const previousOrdersOfThePatient = isExpanded ? order.previousOrders : order.previousOrders.slice(0, 6)

  const isChecked = selectedBatchOrderIds.includes(order.id)

  return (
    <div className='flex flex-col w-full justify-between gap-6 self-stretch rounded-2xl border border-[#D1D9E2] mb-4 bg-white p-6 sm:p-8'>
      <div className='flex items-center'>
        <Checkbox checked={isChecked} onClick={() => handleSelectOrder(order.id)} className='w-8 h-8' />
        <div className='flex h-full py-8 px-12 w-full justify-between items-center rounded-2xl'>
          <Column header='Patient name' text={patientName} />
          <div className='h-full w-[1px] bg-custom-neutral-700' />
          <Column header='Age' text={patientAgeText} />
          <div className='h-full w-[1px] bg-custom-neutral-700' />
          <Column header='Gender' text={patient?.gender} />
          <div className='h-full w-[1px] bg-custom-neutral-700' />
          <Column header='Postcode' text={order?.orderPostCode} />
        </div>
      </div>

      <div className='w-full h-[1px] bg-[#E9EAED] my-8' />

      <div className='mb-6 flex align-items justify-between'>
        <p className='mb-6 text-custom-neutral-600 text-xl font-semibold leading-[133.4%] tracking-normal'>
          Previous 12 Weeks
        </p>
        <button type='button' onClick={() => setIsExpanded(!isExpanded)} className='inline-flex items-center'>
          <span className='text-[#153CB7] text-sm font-medium leading-[170%]'>
            {isExpanded ? 'See less' : 'See more'}
          </span>
          <ChevronDownIcon className={`ml-1 transition-transform fill-[#153CB7] ${isExpanded ? 'rotate-180' : ''}`} />
        </button>
      </div>

      <div className='grid grid-cols-3 gap-4'>
        {previousOrdersOfThePatient.length > 0 ? (
          previousOrdersOfThePatient.map(order => <Order key={order.id} order={order as any} />)
        ) : (
          <p>No previous orders found</p>
        )}
      </div>

      {!!variableQuestions.length && (
        <>
          <div className='w-full h-[1px] bg-[#E9EAED] my-8' />

          <div className='flex flex-col'>
            <p className='text-custom-neutral-600 text-xl font-semibold leading-[133.4%] tracking-normal'>
              Variable Questions
            </p>
            <ConsultationTable data={variableQuestions} />
          </div>
        </>
      )}
    </div>
  )
}

type OrderStatusHeaderProps = {
  status: 'approved' | 'rejected' | 'on-hold'
  orderMedNameString: string
}

function OrderStatusHeader({ status = 'approved', orderMedNameString }: OrderStatusHeaderProps) {
  const statusToPropsMap = {
    approved: {
      svg: <CheckmarkBadge />,
      text: 'The medication request has been approved'
    },
    rejected: {
      svg: <CancelCircle />,
      text: 'The medication request has been rejected'
    },
    'on-hold': {
      svg: <Loading />,
      text: 'The medication request has been placed on hold'
    }
  }

  const props = statusToPropsMap[status]

  return (
    <div className='flex flex-col justify-center items-center'>
      <p className='mb-6'>{props.svg}</p>
      <p className='text-[#24272B] text-center text-2xl font-semibold leading-[150%] tracking-[0.15px]'>{props.text}</p>
      <p className='text-[#24272B] text-center text-base font-normal leading-[150%] tracking-[0.15px]'>
        {orderMedNameString}
      </p>
    </div>
  )
}

const PostOrderView = () => {
  const { toast } = useToast()
  const {
    status,
    setStatus,
    medication,
    justification,
    medicationIssueDate,
    medicationDirection,
    batchOrders,
    selectedBatchOrderIds
  } = useMedicationRequestStore()

  const medRequest = useCurrentMedication()

  const goNextMedRequest = useGoNextMedRequest()

  const isLookingForNewOrders = ['on-hold', 'rejected'].includes(status)

  const isApproveSelectedDisabled = selectedBatchOrderIds.length === 0

  const { mutateAsync } = useMedRequestAction({ isBatch: true })

  if (!medRequest) return null
  const { data } = medRequest

  const handleBatchApproval = async () => {
    if (!data) return
    if (selectedBatchOrderIds.length === 0) return
    if (!medReqFieldValidationNotifications('approve')) return

    const promises = selectedBatchOrderIds.map(id =>
      mutateAsync({
        id,
        action: 'approve',
        reason: justification,
        approveDate: medicationIssueDate.toISOString(),
        extraDirectionsForMedication: medicationDirection
      })
    )

    await Promise.all(promises)
    invalidateRelevantActionQueries(data.patient.id)
    resetMedicationRequestStore()

    toast({
      title: 'Success',
      description: 'Medication requests succesfully approved.'
    })

    goNextMedRequest()
  }

  if (!data) return null

  return (
    <div className='max-w-[1375px] w-full mx-auto'>
      <div className='mb-10 flex px-[88px] py-8 flex-col justify-center items-center rounded-3xl border border-[#E9EAED] bg-[#FAFAFB]'>
        <OrderStatusHeader status='approved' orderMedNameString={data.orderMedNameString} />
        <div className='w-full h-[1px] bg-[#E9EAED] my-8' />
        <div className='flex  py-8 px-2 justify-between items-start rounded-2xl'>
          <div className='flex-shrink-0 pr-10'>
            <p className='mb-2 text-[#1D1D1D] text-base font-bold leading-[170%]'>Prescribed item</p>
            <p className='text-custom-neutral-800 text-base font-normal leading-[170%]'>{medication?.productName}</p>
          </div>
          <div className='px-10 border-l border-r border-[#E9EAED]'>
            <p className='mb-2  text-[#1D1D1D]  text-base font-bold leading-[170%]'>Directions</p>
            <p className='text-custom-neutral-800 text-base font-normal leading-[170%]'>{medicationDirection}</p>
          </div>
          <div className='flex-shrink-0 pl-10'>
            <p className='mb-2  text-[#1D1D1D] text-base font-bold leading-[170%]'>Issue Date</p>
            <p className='text-custom-neutral-800 text-base font-normal leading-[170%]'>
              {medicationIssueDate.toISOString()}
            </p>
          </div>
        </div>
      </div>
      {status === 'approved' && (
        <>
          <p className='mb-6 text-custom-neutral-600 text-xl font-semibold leading-[133.4%] tracking-normal'>
            Batch Approve Orders
          </p>
          {batchOrders.map(order => (
            <BatchOrder key={order.id} order={order} />
          ))}
          <div className='fixed left-0 bottom-0 flex w-full h-[88px] flex-col justify-center items-center flex-shrink-0 border-t border-[#FAFAFB] bg-white shadow-[0px_-8px_80px_0px_rgba(0,0,0,0.10)]'>
            <div className='flex justify-center gap-4 w-full max-w-[994px]'>
              <button
                onClick={handleBatchApproval}
                type='button'
                className={`flex w-[200px] h-14 flex-col justify-center items-center gap-2 rounded-2xl py-4 px-6 transition-colors duration-200 ${
                  isApproveSelectedDisabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#153CB7] hover:bg-[#0F2E8A]'
                }`}
                disabled={isApproveSelectedDisabled}
              >
                <span className='text-white text-center text-sm font-semibold leading-[170%]'>Approve Selected</span>
              </button>
              <button
                onClick={() => {
                  setStatus('pending')
                  resetMedicationRequestStore()
                  goNextMedRequest()
                }}
                type='button'
                className={
                  'bg-white text-slate-700 text-sm font-semibold flex w-[200px] h-14 flex-col justify-center items-center gap-2 rounded-2xl py-4 px-6 transition-colors duration-200 border border-gray-300'
                }
              >
                Skip to Next
              </button>
            </div>
          </div>
        </>
      )}
      {isLookingForNewOrders && (
        <div className='grid mt-[160px] place-items-center'>
          <LoadingSpinner />
          <p className='text-[#393E45] text-2xl font-semibold leading-[150%] tracking-[0.15px] mt-8'>
            We're moving on to the next request.
          </p>
        </div>
      )}
    </div>
  )
}

export default PostOrderView
