import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { useMedicationRequestStore } from '@/lib/stores/medication-request'
import { useEffect } from 'react'
import TextareaWithLabel from '../textarea'
import type { AdditionalNotesType } from '@/types/data'
import { useInvestigationStore } from '@/lib/stores/investigation'
import { useCurrentInvestigation } from '@/hooks/useCurrentInvestigation'

export default function AdditionalNotes({
  isEditable,
  patientOrigin = 'medication-request'
}: {
  isEditable: boolean
  patientOrigin?: 'medication-request' | 'investigation'
}) {
  const { notes: medReqNotes, setNotes: setMedReqNotes, medicationIssueDate, medication } = useMedicationRequestStore()
  const { notes: investigationNotes, setNotes: setInvestigationNotes } = useInvestigationStore()

  const isMedReq = patientOrigin === 'medication-request'
  const notes = isMedReq ? medReqNotes : investigationNotes
  const setNotes = isMedReq ? setMedReqNotes : setInvestigationNotes

  const investigation = useCurrentInvestigation(patientOrigin === 'investigation')
  const medRequest = useCurrentMedication(
    !!(patientOrigin === 'medication-request' || investigation?.data?.medicationRequestId),
    investigation?.data?.medicationRequestId,
    isMedReq
  )

  useEffect(() => {
    const oldNotes = isMedReq ? useMedicationRequestStore.getState().notes : useInvestigationStore.getState().notes

    if (isMedReq) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      oldNotes.pharmacist = oldNotes.pharmacist?.replace(/\nMedication future date:.*\n/g, '')

      if (medicationIssueDate && medicationIssueDate?.getTime() > today.getTime()) {
        oldNotes.pharmacist += `\nMedication future date: ${medicationIssueDate.toLocaleString('en-GB', {
          dateStyle: 'full',
          timeStyle: 'long'
        })}\n`
      }
    }

    setNotes(oldNotes)
  }, [medicationIssueDate])

  useEffect(() => {
    if (!medRequest?.data) return
    const { data } = medRequest
    setNotes({
      pharmacist: data.additionalNotes?.pharmacist ?? '',
      customerService: data.additionalNotes?.customerService ?? ''
    })
  }, [medRequest?.data])

  if (isMedReq && medication?.type === 'OTC') {
    return null
  }

  return (
    <div id='add-notes' className='flex flex-col gap-5'>
      {(['pharmacist', 'customerService'] as (keyof AdditionalNotesType)[]).map(entry => {
        let formattedTitle = ''
        if (entry === 'pharmacist') {
          formattedTitle = 'Pharmacy'
        } else if (entry === 'customerService') {
          formattedTitle = 'Customer Service'
        }

        return (
          <div key={entry}>
            <span className='text-custom-neutral-600 text-xl font-semibold leading-[133.4%] mb-6'>
              {formattedTitle} notes{' '}
              <span className='text-custom-neutral-600  font-normal leading-[170%]'>(Optional)</span>
            </span>
            <p className='text-[#1D1D1D] font-medium leading-[170%] mb-4'>Add Notes</p>
            <TextareaWithLabel
              isDisabled={!isEditable}
              title={`${formattedTitle} notes`}
              value={notes?.[entry] ?? ''}
              onChange={e => {
                const { notes: newNotes } = isMedReq
                  ? useMedicationRequestStore.getState()
                  : useInvestigationStore.getState()
                setNotes({
                  ...newNotes,
                  [entry]: e.target.value
                })
              }}
            />
          </div>
        )
      })}
    </div>
  )
}
