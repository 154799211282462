import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import type { InvestigationResult } from '@/types/data'
import {
  type ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'

export type SanitizedTestResult = InvestigationResult['resultLines'][number]

export const columns: ColumnDef<SanitizedTestResult>[] = [
  {
    accessorKey: 'test-name',
    header: 'Name',
    cell: ({ row }) => <p className='text-[#4E545E] text-base font-medium leading-[170%]'>{row.original.testName}</p>,
    maxSize: 300
  },
  {
    accessorKey: 'result',
    header: 'Result',
    cell: ({ row }) => {
      const line = row.original
      const hasUOM = line.UOM && line.UOM.trim() !== ''
      const hasRange = line.rangeText && line.rangeText.trim() !== ''
      const hasFlag = line.flag && line.flag.trim() !== ''

      return (
        <div className='text-base leading-[170%] whitespace-pre text-[#4E545E]'>
          {line.result}
          {hasUOM && ` ${line.UOM}`}
          {hasRange && ` (${line.rangeText})`}
          {hasFlag && ` [${line.flag}]`}
        </div>
      )
    }
  }
]

export function TestsResultsTable({
  investigationResults
}: {
  investigationResults: InvestigationResult[]
}) {
  const sanitizedResults = investigationResults.map(entry => entry.resultLines).flat()

  const table = useReactTable({
    data: sanitizedResults,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    manualPagination: true
  })

  if (!investigationResults.length) return null

  return (
    <div id='test-results' className='w-full flex flex-col gap-6'>
      <h3 className='text-xl font-semibold text-[#626A77]'>Test results</h3>
      <div className='[&_>_div]:rounded-2xl [&_>_div]:border [&_>_div]:border-[#E9EAED]'>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow className='bg-[#F0F1F3]' key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <TableHead className='px-[56px] py-3 font-bold text-sm leading-6 text-[#1D1D1D]' key={header.id}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody className='border-0'>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map(row => {
                return (
                  <TableRow key={row.id}>
                    {row.getVisibleCells().map(cell => (
                      <TableCell className='px-[56px] py-4' key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell colSpan={columns?.length} className='h-24 text-center'>
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}
