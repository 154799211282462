import { Button } from '@/components/ui/button'
import useMedRequestFiltering from '@/lib/api/med-requests/filtering'
import { resetFilterStore, useFilterStore } from '@/lib/stores/filter'
import { useEffect, type ComponentPropsWithoutRef } from 'react'
import { useGoNextMedRequest } from '@/hooks/useGoNextMedRequest'
import { useLocation } from 'react-router-dom'
import useInvestigationFiltering from '@/lib/api/investigations/filtering'
import { useGoNextInvestigation } from '@/hooks/useGoNextInvestigation'
import { useUser } from '@clerk/clerk-react'

const SHIPPING_TYPES_LABELS: Record<string, string> = {
  'Royal Mail Special Delivery Next Day by 1pm™ Signed For': 'Special Next Day',
  'Royal Mail 24 Tracked™': '24 Tracked'
  // 'Royal Mail 48 Tracked™': '48 Tracked',
  // 'Free Shipping': 'Free'
}

function ToggleButton({
  children,
  onToggle,
  toggle = false
}: {
  onToggle: (value: boolean) => void
  toggle?: boolean
} & ComponentPropsWithoutRef<'button'>) {
  return (
    <Button variant={toggle ? 'toggleOn' : 'toggleOff'} onClick={() => onToggle(!toggle)}>
      {children}
    </Button>
  )
}

const { setFilteringOptions, updateFilteringOptions } = useFilterStore.getState()

export default function Filter() {
  const { user } = useUser()
  const isJuniorPrescriber = user?.publicMetadata.roles.includes('JUNIOR_PRESCRIBER')

  const { filteringOptions } = useFilterStore()
  const hasInvestigationFilter = filteringOptions.investigations

  const { isPending: investigationsPending, data: investigations } = useInvestigationFiltering(!isJuniorPrescriber)
  const { isPending: medRequestsPending, data: medRequests } = useMedRequestFiltering(!hasInvestigationFilter)

  const hasFilters = Object.values(filteringOptions).some(
    value => (typeof value === 'boolean' && value) || (Array.isArray(value) && value.length > 0)
  )

  const data = hasInvestigationFilter ? investigations : medRequests
  const isPending = hasInvestigationFilter ? investigationsPending : medRequestsPending

  const goNextMedRequest = useGoNextMedRequest(!hasInvestigationFilter)
  const goNextInvestigation = useGoNextInvestigation(hasInvestigationFilter && !isJuniorPrescriber)
  const goNextRequest = hasInvestigationFilter ? goNextInvestigation : goNextMedRequest

  const location = useLocation()
  useEffect(() => {
    resetFilterStore()
  }, [location])

  return (
    <div className='px-10 py-8 rounded-2xl border border-custom-neutral-700 bg-white flex flex-col gap-6'>
      <div className='flex items-center gap-4'>
        <h3 className='font-poppins text-sm leading-5'>Shipping Type:</h3>

        <div className='flex items-center gap-2'>
          {Object.values(SHIPPING_TYPES_LABELS).map((label, index) => (
            <ToggleButton
              key={index}
              toggle={filteringOptions['Shipping Method'].includes(Object.keys(SHIPPING_TYPES_LABELS)[index])}
              onToggle={isOn => {
                const { filteringOptions } = useFilterStore.getState()

                const labelKey = Object.keys(SHIPPING_TYPES_LABELS).find(
                  key => SHIPPING_TYPES_LABELS[key as string] === label
                )
                if (!labelKey) {
                  return
                }

                let existingFilterData = filteringOptions['Shipping Method']
                if (isOn) {
                  existingFilterData = [...existingFilterData, labelKey]
                } else {
                  existingFilterData = existingFilterData.filter(key => key !== labelKey)
                }

                setFilteringOptions({
                  ...filteringOptions,
                  'Shipping Method': [...new Set(existingFilterData)],
                  investigations: false
                })
              }}
            >
              {label}
            </ToggleButton>
          ))}
        </div>
      </div>

      <div className='min-h-[1px] w-full bg-[#E9EAED]' />

      <div className='flex items-center gap-4'>
        <h3 className='font-poppins text-sm leading-5'>Advanced Filters:</h3>

        <ToggleButton
          toggle={filteringOptions.priority}
          onToggle={isOn =>
            updateFilteringOptions(filteringOptions => ({
              ...filteringOptions,
              priority: !!isOn,
              investigations: false
            }))
          }
        >
          Priority
        </ToggleButton>

        <ToggleButton
          toggle={filteringOptions['ID Verified Time'].length > 0}
          onToggle={isOn =>
            updateFilteringOptions(filteringOptions => ({
              ...filteringOptions,
              'ID Verified Time': isOn ? ['after'] : [],
              investigations: false
            }))
          }
        >
          Before Cutoff
        </ToggleButton>

        <ToggleButton
          toggle={filteringOptions['onHolds']}
          onToggle={isOn =>
            updateFilteringOptions(filteringOptions => ({
              ...filteringOptions,
              onHolds: !!isOn,
              investigations: false
            }))
          }
        >
          On Holds
        </ToggleButton>

        {!isJuniorPrescriber && (
          <ToggleButton
            toggle={filteringOptions.investigations}
            onToggle={isOn => {
              resetFilterStore()
              updateFilteringOptions(filteringOptions => ({
                ...filteringOptions,
                investigations: !!isOn
              }))
            }}
          >
            Investigations
          </ToggleButton>
        )}
      </div>

      <Button
        type='button'
        disabled={isPending || !data?.length}
        className='self-center mt-6'
        onClick={() => goNextRequest()}
      >
        {isPending
          ? 'Loading'
          : !hasFilters && data?.length
            ? 'View all'
            : data?.length
              ? `View ${data.length}${data.length === 200 ? '+' : ''}`
              : 'No'}{' '}
        {hasInvestigationFilter ? 'investigations' : 'medication requests'}
        {isPending && '...'}
      </Button>
    </div>
  )
}
