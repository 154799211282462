import { toast } from '@/hooks/use-toast'
import React from 'react'
import * as Sentry from '@sentry/react'

interface Props {
  fallback?: React.ReactNode
  children: React.ReactNode
}

export class ErrorBoundary extends React.Component<Props, { hasError: boolean; error: any }> {
  constructor(props: Props) {
    super(props)
    this.state = {
      hasError: false,
      error: ''
    }
  }

  static getDerivedStateFromError(error: any) {
    return {
      hasError: true,
      error: error?.message || error
    }
  }

  componentDidCatch(error: any) {
    Sentry.captureException(error)
    toast({
      description: (
        <div className='text-red-700'>
          <p className='font-bold'>{this.props.fallback}</p>
          <p>{error?.message || error}</p>
        </div>
      )
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='text-red-700'>
          <p className='font-bold'>{this.props.fallback}</p>
          <p>{this.state.error?.message || this.state.error}</p>
        </div>
      )
    }

    return this.props.children
  }
}
