import { toast } from '@/hooks/use-toast'
import axios from 'axios'
import * as Sentry from '@sentry/react'

const http = axios.create({
  baseURL: import.meta.env.VITE_NODE_API_URL,
  timeout: 10000,
  timeoutErrorMessage: 'Request timed out',
  adapter: 'fetch'
})

http.interceptors.request.use(async req => {
  const token = await window.Clerk.session?.getToken()
  req.headers.Authorization = `Bearer ${token}`

  return req
})

http.interceptors.response.use(
  res => res.data,
  error => {
    toast({
      description: (
        <div>
          <p className='text-red-700 font-bold'>{error.response?.data?.message || error.response?.data}</p>
        </div>
      )
    })

    const url: string = error.config?.url
    const status = error.response?.status
    const isFEError = status >= 400 && status < 500

    try {
      const body = error.config?.data

      const isJsonHeaders = error.config?.headers?.['Content-Type'] === 'application/json'
      const parcedBody = body && isJsonHeaders ? JSON.parse(body) : body

      const hashedUrl = url
        ?.split('/')
        .map(e => (e.length === 30 ? ':hash' : e))
        .join('/')
      error.name = 'API Error'
      error.message = `URL:${hashedUrl} ${error.message}`

      const name = `API Error : ${error.config?.method.toUpperCase()} ${hashedUrl} ${status}`
      Sentry.captureMessage(isFEError ? name : error, {
        level: isFEError ? 'debug' : 'error',
        contexts: {
          http_error: {
            hasJWT: !!error.config?.headers?.Authorization,
            request: {
              headers: error.config?.headers,
              body: parcedBody
            },
            response: {
              headers: error.response?.headers,
              body: error.response?.data,
              status: error.response?.status
            },
            retryCount: error.config?.['axios-retry']?.retryCount,
            code: error.code,
            ALL_DATA: error
          }
        },
        tags: {
          API_RESPONSE_MSG: error.response?.data?.message ?? null
        }
      })
    } catch (e) {
      console.error('Error in HTTP response error try method', e)
      Sentry.captureException(e)
      Sentry.captureMessage(error.name, isFEError ? 'debug' : 'error')
    }

    return Promise.reject(error.response?.data)
  }
)

export default http
