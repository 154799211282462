import { useTotalMedicationRequests } from '@/services/medicationRequests'
import { format, isAfter } from 'date-fns'
import { enGB } from 'date-fns/locale'
import { ComponentPropsWithoutRef } from 'react'
import { cn } from '@/lib/utils'

export function AccessTimeFilledRounded({ className, ...props }: ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      width='20'
      height='21'
      viewBox='0 0 20 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={cn('text-[#080F30]', className)}
      {...props}
    >
      <path
        d='M9.99 0.5C4.47 0.5 0 4.98 0 10.5C0 16.02 4.47 20.5 9.99 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 9.99 0.5ZM14 14.5C13.61 14.89 12.98 14.89 12.59 14.5L9.3 11.21C9.11 11.02 9 10.77 9 10.5V6.5C9 5.95 9.45 5.5 10 5.5C10.55 5.5 11 5.95 11 6.5V10.09L14 13.09C14.39 13.48 14.39 14.11 14 14.5Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default function DisplayCutoffTimeNotice() {
  const {
    data: { cutoffTimes }
  } = useTotalMedicationRequests()

  const now = new Date(new Date().toLocaleString('en-US', { timeZone: 'Europe/London' }))
  const currentDay = format(now, 'EEEE').toLowerCase() as keyof typeof cutoffTimes

  const todayCutoff = cutoffTimes[currentDay]
  const [cutoffHours, cutoffMinutes] = todayCutoff.cutoff_time.split(':')
  const cutoffTime = new Date(now)
  cutoffTime.setHours(parseInt(cutoffHours, 10), parseInt(cutoffMinutes, 10), 0)

  const isAfterCutoff = isAfter(now, cutoffTime)
  const formattedCutoffTime = format(cutoffTime, 'h:mm a', { locale: enGB })

  return (
    <div
      className={cn(
        'py-[20px] rounded-2xl border border-[#080F30] px-[26px] flex items-center gap-4 bg-custom-gray-3',
        isAfterCutoff && 'border-[#FF8080] bg-[#FFF2F2]'
      )}
    >
      <AccessTimeFilledRounded className={cn(isAfterCutoff && 'text-[#FF8080]')} />
      <p className='font-poppins text-base'>
        The cutoff for today{' '}
        {isAfterCutoff ? (
          <>
            was at {formattedCutoffTime}. <strong>Please use the 'Before Cutoff' filter</strong> if prescribing after
            this time.
          </>
        ) : (
          <>
            is at <strong>{formattedCutoffTime}</strong>.
          </>
        )}
      </p>
    </div>
  )
}
