import { useCurrentInvestigation } from '@/hooks/useCurrentInvestigation'
import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import { cn, getPatientAgeText } from '@/lib/utils'
import type { MedRequest } from '@/types/data'
import { useMemo } from 'react'

export function Column({
  header,
  text
}: {
  header: string
  text: string | null
}) {
  return (
    <div className='w-2/12'>
      <p className='mb-2 text-[#1D1D1D] font-bold leading-[170%]'>{header}</p>
      <p className='text-custom-neutral-800 leading-[170%]'>{text ?? '-'}</p>
    </div>
  )
}

export function RedFlagIcon({ ...props }) {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M12 2.5C6.48 2.5 2 6.98 2 12.5C2 18.02 6.48 22.5 12 22.5C17.52 22.5 22 18.02 22 12.5C22 6.98 17.52 2.5 12 2.5ZM17 15.5H13.62C13.24 15.5 12.89 15.29 12.73 14.95L12 13.5H9.5V17.75C9.5 18.16 9.16 18.5 8.75 18.5C8.34 18.5 8 18.16 8 17.75V8.5C8 7.95 8.45 7.5 9 7.5H13.38C13.76 7.5 14.11 7.71 14.27 8.05L15 9.5H17C17.55 9.5 18 9.95 18 10.5V14.5C18 15.05 17.55 15.5 17 15.5Z'
        fill='#FF8080'
      />
    </svg>
  )
}

export function PatientRedFlag({
  patient
}: {
  patient?: MedRequest['patient']
}) {
  if (!patient?.redFlagReason) return null

  return (
    <div className='flex gap-2 bg-[#FFE5E5] rounded-2xl py-3 px-4'>
      <RedFlagIcon className='shrink-0' />
      <span className='text-[#FF8080] text-lg font-medium font-poppins'>{patient.redFlagReason}</span>
    </div>
  )
}

export default function PatientData({
  patientOrigin = 'medication-request'
}: {
  patientOrigin?: 'investigation' | 'medication-request'
}) {
  const medRequestQuery = useCurrentMedication(patientOrigin === 'medication-request')
  const medRequest = medRequestQuery?.data
  const { data: investigation } = useCurrentInvestigation(patientOrigin === 'investigation')

  const patient = medRequest?.patient ?? investigation?.patient

  const patientAgeText = useMemo(() => getPatientAgeText(patient?.dob), [patient?.dob])

  const patientName = patient?.firstName ? `${patient?.firstName} ${patient?.lastName}` : '-'

  const optionalFields = useMemo(() => {
    if (!medRequest) return []
    return (
      ['familyHistory', 'allergies', 'conditions', 'medications', 'socialHistory', 'otherObservations'] as Array<
        keyof MedRequest['patient']
      >
    )
      .map(field => {
        if (patient?.[field]) {
          let label: string = field
          if (field === 'otherObservations') {
            label = 'Other'
          } else if (field === 'socialHistory') {
            label = 'Social history'
          } else if (field === 'familyHistory') {
            label = 'Family history'
          }

          return {
            label,
            value: patient[field]
          }
        }
      })
      .filter(Boolean)
  }, [medRequest])

  return (
    <div id='patient-data'>
      <p className='text-custom-neutral-600 text-xl font-semibold leading-[133.4%] mb-4'>Patient data</p>
      <div className='flex flex-col gap-6 rounded-2xl border border-custom-neutral-700 bg-white p-8'>
        <PatientRedFlag patient={patient} />
        <div className='flex min-h-[88px] justify-between'>
          <Column header='Patient name' text={patientName} />
          <div className='min-h-full w-[1px] bg-custom-neutral-700' />
          <Column header='Age' text={patientAgeText} />
          <div className='min-h-full w-[1px] bg-custom-neutral-700' />
          <Column header='Gender' text={patient?.gender as string} />
          <div className='h-full w-[1px] bg-custom-neutral-700' />
          {medRequest && <Column header='Postcode' text={medRequest.orderPostCode} />}
        </div>

        {!!optionalFields?.length && (
          <>
            <div className='min-h-[1px] bg-custom-neutral-700 w-full' />

            <div className='flex flex-wrap gap-10 w-full items-start'>
              {optionalFields.map((field, index) => {
                if (!field?.value) return null

                if (index % 2 === 0 && optionalFields[index + 1]) {
                  return (
                    <div key={index} className='flex gap-[50px] w-[calc(50%_-_51px)]'>
                      <div className='flex flex-col gap-2 w-full'>
                        <p className='text-[#1D1D1D] font-bold text-base leading-7 capitalize'>{field.label}</p>
                        <p className='text-custom-neutral-800 text-base leading-7'>{field.value}</p>
                      </div>

                      <div className='min-h-full w-[1px] bg-custom-neutral-700' />
                    </div>
                  )
                }

                return (
                  <div key={index} className='w-[calc(50%_-_48px)] flex flex-col gap-2'>
                    <p className='text-[#1D1D1D] font-bold text-base leading-7 capitalize'>{field.label}</p>
                    <p className='text-custom-neutral-800 text-base leading-7'>{field.value}</p>
                  </div>
                )
              })}
            </div>
          </>
        )}
      </div>
    </div>
  )
}
