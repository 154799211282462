import http from '@/lib/http'
import type { BatchMedRequest } from '@/types/data'
import { useQuery } from '@tanstack/react-query'

export default function useBatchApproval({
  medRequestId,
  enabled
}: {
  medRequestId: string
  enabled?: boolean
}) {
  return useQuery<BatchMedRequest[]>({
    queryKey: ['batchMedicationRequestById', medRequestId],
    queryFn: () => http.get(`/prescriber/med-requests/${medRequestId}/batch`),
    enabled
  })
}
