import useInvestigationAction from './action'
import { useGoNextInvestigation } from '@/hooks/useGoNextInvestigation'
import { useInvestigationStore } from '@/lib/stores/investigation'
import { useCurrentInvestigation } from '@/hooks/useCurrentInvestigation'

export default function useApproveInvestigation(props: {
  patientOrigin?: 'medication-request' | 'investigation'
  message: string
}) {
  const { patientOrigin = 'medication-request', message } = props
  const investigation = useCurrentInvestigation(patientOrigin === 'investigation')
  const goNextInvestigation = useGoNextInvestigation(patientOrigin === 'investigation')
  const action = useInvestigationAction()

  return () => {
    if (!investigation?.data) return

    const { notes, setJustification } = useInvestigationStore.getState()
    action.mutate({
      id: investigation.data.id,
      action: 'approve',
      message: message,
      additionalNotes: notes
    })

    goNextInvestigation()
    setJustification('')
  }
}
