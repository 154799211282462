import { useNavigate } from 'react-router-dom'
import { toast } from './use-toast'
import usePrepareNextRequest from './usePrepareNextRequest'

export const useGoNextInvestigation = (enabled = false) => {
  const navigate = useNavigate()
  const nextInvestigation = usePrepareNextRequest(enabled, true)

  const handleNextInvestigationRequest = async () => {
    if (nextInvestigation) {
      navigate(`/investigation/${nextInvestigation.id}`)
    } else {
      toast({
        description: 'We couldnt find a new request for you.'
      })
      navigate('/')
    }
  }

  return handleNextInvestigationRequest
}
