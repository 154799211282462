import { ErrorBoundary } from '@/components/common/ErrorBoundary'
import { MedicationRequestInfo } from '@/components/common/medicationrequestinfo'
import { useUser } from '@clerk/clerk-react'
import { Suspense } from 'react'
import Filter from './filter'
import SearchInput from '@/components/ui/search-input'
import { resetFilterStore, useFilterStore } from '@/lib/stores/filter'
import DisplayCutoffTimeNotice from '@/components/common/cutoff-time-notice'

const { updateFilteringOptions } = useFilterStore.getState()

export default function DashboardPage() {
  const { user } = useUser()
  const { filteringOptions } = useFilterStore()
  const resetSearchFiltering = () =>
    updateFilteringOptions(options => ({
      ...options,
      'Order Number': []
    }))

  return (
    <section className='flex flex-col gap-8'>
      <div className='flex items-start justify-between'>
        <div className='flex flex-col gap-2'>
          <h2 className='capitalize text-[2rem] font-semibold leading-10 text-secondary'>
            Welcome {user?.fullName || user?.primaryEmailAddress?.emailAddress}
          </h2>

          <p className='leading-6 text-sm font-normal text-custom-neutral-500'>
            Use filters to prioritize medication requests that need action.
          </p>
        </div>

        <SearchInput
          value={filteringOptions['Order Number'][0] || ''}
          onChange={searchQuery => {
            if (searchQuery === '') {
              resetSearchFiltering()
            } else {
              resetFilterStore()
              updateFilteringOptions(options => ({
                ...options,
                'Order Number': [searchQuery]
              }))
            }
          }}
          onCancel={resetSearchFiltering}
        />
      </div>

      <ErrorBoundary fallback='Error loading totals'>
        <Suspense>
          <MedicationRequestInfo />
          <DisplayCutoffTimeNotice />
        </Suspense>
      </ErrorBoundary>

      <Filter />
    </section>
  )
}
