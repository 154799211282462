type Props = {
  template?: string
  isLoading: boolean
  error: boolean
}

const InvestigationTemplatePreview = ({ template, isLoading, error }: Props) => {
  return (
    <>
      {isLoading && (
        <div className='border -mt-10 h-[360px] overflow-y-auto p-2 mx-2 my-4 rounded cursor-not-allowed [&>a]:text-primary'>
          Loading...
        </div>
      )}
      {error && (
        <div className='border -mt-10 h-[360px] overflow-y-auto p-2 mx-2 my-4 rounded cursor-not-allowed [&>a]:text-primary'>
          Error loading template
        </div>
      )}
      {!isLoading && !error && template && (
        <div
          dangerouslySetInnerHTML={{ __html: template }}
          className='border -mt-10 h-[360px] overflow-y-auto p-2 mx-2 my-4 rounded cursor-not-allowed [&>a]:text-primary'
        />
      )}
    </>
  )
}

export default InvestigationTemplatePreview
