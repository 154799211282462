import http from '@/lib/http'
import { useFilterStore } from '@/lib/stores/filter'
import type { MedRequest } from '@/types/data'
import { useQuery } from '@tanstack/react-query'

const fetchFilteredMedRequests = ({
  orderNumbers,
  shippingMethods,
  cutoffTime,
  priority,
  onHolds
}: {
  orderNumbers: Array<string>
  shippingMethods: Array<string>
  cutoffTime?: 'before' | 'after'
  priority?: boolean
  onHolds?: boolean
}) => {
  const params = new URLSearchParams()

  if (orderNumbers?.length) {
    params.append('orderNumbers', orderNumbers.join(','))
  }

  if (shippingMethods?.length) {
    params.append('shippingMethods', shippingMethods.join(','))
  }

  if (cutoffTime) {
    params.append('cutoffTime', cutoffTime)
  }

  if (priority) {
    params.append('priority', 'true')
  }

  if (onHolds) {
    params.append('onHolds', 'true')
  }

  return http.get<Array<MedRequest>, Array<MedRequest>>('/prescriber/med-requests', {
    params,
    timeout: (http.defaults.timeout ?? 10000) * 6
  })
}

const useMedRequestFiltering = (enabled = true) => {
  const { filteringOptions, getCacheKey } = useFilterStore()

  // @ts-expect-error cutoffTime is a string
  const cutoffTime = filteringOptions['Cutoff Time']?.[0] as 'before' | 'after' | undefined

  const orderNumbers = filteringOptions['Order Number']
  const shippingMethods = filteringOptions['Shipping Method']
  const priority = filteringOptions.priority
  const onHolds = filteringOptions.onHolds

  return useQuery<Array<MedRequest>>({
    queryKey: getCacheKey(),
    queryFn: async () =>
      fetchFilteredMedRequests({
        orderNumbers,
        shippingMethods,
        cutoffTime,
        priority,
        onHolds
      }),
    refetchInterval: 30000,
    enabled
  })
}

export default useMedRequestFiltering
