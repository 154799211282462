import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { useCurrentInvestigation } from '@/hooks/useCurrentInvestigation'
import { useCurrentMedication } from '@/hooks/useCurrentMedication'
import usePreviousInvestigations from '@/hooks/usePreviousInvestigations'
import usePatientOrderHistory from '@/lib/api/med-requests/order-history'
import { useUser } from '@clerk/clerk-react'

const getSections = (
  scrData: Record<string, unknown> | null,
  hasOnHoldHistory: boolean | null,
  hasPreviousOrders?: boolean,
  hasPreviousInvestigations?: boolean,
  hasTestResults?: boolean,
  isInvestigation?: boolean
) => {
  const scrDataSubFields = scrData
    ? Object.keys(scrData)
        .filter(key => !['date_of_birth', 'first_name', 'last_name', 'nhs_number', 'postcode'].includes(key))
        .map(key => ({
          id: `scr-section-${key}`,
          label: key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())
        }))
    : []

  const sections = [
    {
      title: 'Patient data',
      subfields: [{ id: 'patient-data', label: 'Patient data' }]
    },
    hasPreviousOrders
      ? {
          title: 'Previous orders',
          subfields: [{ id: 'previous-orders', label: 'Previous orders' }]
        }
      : false,
    hasPreviousInvestigations
      ? {
          title: 'Previous investigations',
          subfields: [{ id: 'previous-investigations', label: 'Previous investigations' }]
        }
      : false,
    hasTestResults
      ? {
          title: 'Test results',
          subfields: [{ id: 'test-results', label: 'Test results' }]
        }
      : false,
    hasOnHoldHistory
      ? {
          title: 'On hold history',
          subfields: [{ id: 'on-hold-history', label: 'On hold history' }]
        }
      : false,
    !isInvestigation
      ? {
          title: 'Consultation',
          subfields: [{ id: 'consultation', label: 'Consultation' }]
        }
      : null,
    !isInvestigation
      ? {
          title: 'SCR data',
          subfields: scrDataSubFields
        }
      : null,
    !isInvestigation
      ? {
          title: 'Prescription details',
          subfields: [{ id: 'prescription-details', label: 'Prescription Details' }]
        }
      : null,
    /*   {
      title: "Prescriber justification",
      subfields: [{ id: "add-justification", label: "Add justification" }],
    },
  */
    {
      title: 'Additional notes',
      subfields: [{ id: 'add-notes', label: 'Add Notes' }]
    }
  ].filter(Boolean) as {
    title: string
    subfields: Array<{ id: string; label: string }>
  }[]

  return sections
}

const scrollToSection = (id: string) => {
  const element = document.getElementById(id)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }
}

export default function NavigationMenu({
  patientId,
  patientOrigin = 'medication-request',
  withoutInvestigationId,
  hasTestResults,
  isInvestigation
}: {
  patientId: string
  patientOrigin?: 'medication-request' | 'investigation'
  withoutInvestigationId?: string
  hasTestResults?: boolean
  isInvestigation?: boolean
}) {
  const { user } = useUser()
  const isJuniorPrescriber = user?.publicMetadata.roles.includes('JUNIOR_PRESCRIBER')

  const investigation = useCurrentInvestigation(patientOrigin === 'investigation')
  const medRequest = useCurrentMedication(
    !!(patientOrigin === 'medication-request' || investigation?.data?.medicationRequestId),
    investigation?.data?.medicationRequestId,
    patientOrigin === 'medication-request'
  )
  const medRequestData = medRequest?.data

  const { data: previousInvestigationsData } = usePreviousInvestigations({
    patientId,
    enabled: !isJuniorPrescriber
  })
  const previousInvestigations = previousInvestigationsData?.filter(entry => entry.id !== withoutInvestigationId)

  const { data: previousOrders } = usePatientOrderHistory(patientId)
  const scrData = medRequestData?.patient?.scrData ? JSON.parse(medRequestData.patient.scrData) : null

  const sections = getSections(
    scrData,
    Boolean(medRequestData?.reasonForHold || medRequestData?.patientsResponseForHold),
    !!previousOrders?.length,
    !!previousInvestigations?.length,
    hasTestResults,
    isInvestigation
  )

  return (
    <div className='sticky py-2 overflow-y-auto top-[88px] min-h-[270px] max-h-[68vh] flex w-[300px] flex-col rounded-2xl border border-custom-neutral-200 shadow-[0px_0px_1px_0px_rgba(113,128,150,0.04),_0px_4px_8px_0px_rgba(113,128,150,0.08)]'>
      <div className='p-6 mb-4'>
        <p className='text-custom-neutral-600 text-xl font-semibold leading-[133.4%]'>Navigation Menu</p>
      </div>

      <Accordion type='single' collapsible>
        {sections.map((section, index) => (
          <AccordionItem key={index} value={`item-${index + 1}`}>
            <AccordionTrigger
              chevronDisabled={section.subfields.length <= 1}
              className='py-6 px-7 h-[69px] text-[#393E45] font-medium leading-[133.4%] flex items-center data-[state=open]:bg-custom-neutral-700'
              onClick={() =>
                section.subfields.length <= 1 && section.subfields[0]?.id && scrollToSection(section.subfields[0].id)
              }
            >
              <span>• {section.title}</span>
            </AccordionTrigger>

            {section.subfields.length > 1 && (
              <AccordionContent className='px-14 pb-3'>
                {section.subfields.map((subfield, subIndex) => (
                  <button
                    type='button'
                    onClick={() => scrollToSection(subfield.id)}
                    key={subIndex}
                    className='flex text-custom-neutral-800 leading-[170%] pt-3 truncate'
                  >
                    • {subfield.label}
                  </button>
                ))}
              </AccordionContent>
            )}
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  )
}
