import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import http from '@/lib/http'
import type { Flag, ParsedQuestionnaire } from '@/types/data'
import { useGetFlags } from '@/services/flags'

export type ReasoningTemplate = {
  name: string
  text: string
}

export type Questionnaire = {
  hash: string
  title: string
  questions_count: number
  updated_at: string
  tags: string[]
  reasoning_templates: {
    prescriber_justification: ReasoningTemplate[] | null
    on_hold_reasons: ReasoningTemplate[] | null
    rejection_reasons: ReasoningTemplate[] | null
  }
  batch_approval: boolean
}

const BATCH_FLAGS = [
  '7c356d8d4f0a5ff90a4089397582bd', // staging/dev
  'bd00417aa21f4e5c4c2b323b44706f' // prod
]

function parseQuestionnaire(str: string, flagsMap: Record<string, Flag>) {
  const cleanedJsonString = str.replace(/[\n\r\t]/g, '') // Remove newlines, carriage returns, and tabs
  let result = JSON.parse(cleanedJsonString) as Array<unknown>

  // @ts-expect-error use any for now
  const parseLinksAndHasFlags = (obj: any) => {
    if (Array.isArray(obj)) {
      return obj.map(parseLinksAndHasFlags)
    }

    if (obj?.flags?.length > 0) {
      const flags = obj.flags as string[]
      const validFlags = flags.filter((flag: string) => flagsMap[flag] && !BATCH_FLAGS.includes(flag))
      const hasSomeNestedBatchFlag = BATCH_FLAGS.some(entry =>
        // @ts-ignore
        obj?.options?.some(opt =>
          // @ts-ignore
          opt?.logic?.flags?.some(flag => flag.indexOf(entry) > -1)
        )
      )

      obj.hasFlags = !!validFlags.length && !hasSomeNestedBatchFlag
      obj.isBatch = flags.some(flag => BATCH_FLAGS.includes(flag)) || hasSomeNestedBatchFlag
    }

    if (obj?.title?.includes('Provide picture') || obj?.type === 'uploader') {
      obj.link = `https://workdrive.zohoexternal.com/external/${obj.answer}`
      obj.imageUploaded = true
    }

    return obj
  }

  result = parseLinksAndHasFlags(result)

  return result as ParsedQuestionnaire[]
}

const mapTemplateToOptions = (templates: ReasoningTemplate[]) => {
  if (!templates?.length) return []
  return templates.map(template => ({
    label: template.name,
    value: template.text
  }))
}

const mapTemplatesIntoArrays = (parsedQuestions: ParsedQuestionnaire[], allQuestions: Questionnaire[]) => {
  const approvalTemplates: ReasoningTemplate[] = []
  const rejectionTemplates: ReasoningTemplate[] = []
  const onHoldTemplates: ReasoningTemplate[] = []

  if (!allQuestions || parsedQuestions.length === 0) {
    return { approvalTemplates, rejectionTemplates, onHoldTemplates }
  }

  const questionnaireHash = parsedQuestions[0]?.questionnaire

  const item = allQuestions.find((q: Questionnaire) => q.hash === questionnaireHash)

  const reasoningTemplates = item?.reasoning_templates

  if (reasoningTemplates?.prescriber_justification)
    approvalTemplates.push(...reasoningTemplates.prescriber_justification)
  if (reasoningTemplates?.on_hold_reasons) onHoldTemplates.push(...reasoningTemplates.on_hold_reasons)
  if (reasoningTemplates?.rejection_reasons) rejectionTemplates.push(...reasoningTemplates.rejection_reasons)

  return { approvalTemplates, rejectionTemplates, onHoldTemplates }
}

export const useQuestionnaire = (questionaireJson: string) => {
  const { data: flagsMap } = useGetFlags()

  const parsedQuestions = useMemo(() => {
    if (!questionaireJson || !flagsMap) return []
    return parseQuestionnaire(questionaireJson, flagsMap)
  }, [questionaireJson, flagsMap])

  const { data } = useQuery({
    queryKey: ['questionnaireData'],
    queryFn: () => http.get<Questionnaire[], Questionnaire[]>('/prescriber/questionnaires'),
    staleTime: 1000 * 60 * 60,
    select: data => {
      const mappedTemplates = mapTemplatesIntoArrays(parsedQuestions, data)
      const approvalOptions = mapTemplateToOptions(mappedTemplates.approvalTemplates)
      const rejectionOptions = mapTemplateToOptions(mappedTemplates.rejectionTemplates)
      const onHoldOptions = mapTemplateToOptions(mappedTemplates.onHoldTemplates)

      return { approvalOptions, rejectionOptions, onHoldOptions }
    }
  })

  return { parsedQuestions, templateOptions: data }
}
