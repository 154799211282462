const afterApprovalStatuses = [
  'Approved for future',
  'Approved',
  'Awaiting post payment',
  'Awaiting fulfillment',
  'Picked',
  'Dispensed',
  'Checked',
  'Packed',
  'Shipped',
  'Delivered',
  'Completed'
]

const afterRejectionStatuses = ['Order rejected', 'Refunded', 'Returned', 'Cancelled']

export { afterApprovalStatuses, afterRejectionStatuses }
